import { ChakraProvider } from "@chakra-ui/react"
import "./index.css"

import { RouterProvider } from "react-router-dom"
import router from "~/routing/router"
import useTokenExpiration from "./_helpers/auth-use-token-expiration"

function App() {
  useTokenExpiration()
  return (
    <ChakraProvider>
      <RouterProvider router={router} />
    </ChakraProvider>
  )
}

export default App
