import { message } from "antd"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import routeUrls from "~/routing/routes"
import { getUserData, USER_ROLES } from "~/stores/user-data"

const useIsAdmin = () => {
  const { role } = getUserData()
  const navigate = useNavigate()
  useEffect(() => {
    role !== USER_ROLES.admin &&
      (function () {
        navigate(routeUrls.authentication.root, { replace: true })
      })()
  }, [role])
}

export default useIsAdmin
