import React from "react"
import tmxLogo from "~/assets/images/tmx-logo.svg"

interface ILogo {}

const Logo: React.FunctionComponent<
  ILogo & React.ImgHTMLAttributes<HTMLImageElement>
> = (props) => <img src={tmxLogo} {...props} />

export default Logo
