export default function WelcomeBlock() {
  return (
    <div className="flex flex-col gap-2 bg-gray-100 w-fit p-2 rounded-lg border">
      <p>
        <strong>Tanzania Mercantile Exchange (TMX)</strong> is a platform where
        farmers, traders, exporters and other various market actors are able to
        access domestic
        <br />
        market and obtain a fair price in selling or buying commodities.
        <br />
        <br />
        The organized marketplace provides a platform where buyers and sellers
        come together to trade, assured of quality, quantity, payment and
        delivery.
      </p>
    </div>
  )
}
