import { ChevronDownIcon } from "@chakra-ui/icons"
import {
  Box,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react"
import { BiUser } from "react-icons/bi"
import { FiChevronDown, FiLogOut, FiSettings } from "react-icons/fi"
import { useNavigate } from "react-router-dom"
import SharedText from "~/components/shared/compoents/text"
import routeUrls from "~/routing/routes"
import { useGetThemeColors } from "~/stores/theme"
import { getUserData, useUserInfoStore } from "~/stores/user-data"
import { clearTokens } from "~/_helpers/auth-token-utils"

const MobileProfile: React.FunctionComponent = () => {
  const { clearData } = useUserInfoStore()
  const navigate = useNavigate()

  const colors = useGetThemeColors()
  const { name, getRole } = getUserData()

  const { fnameInitial, lnameInitial } = (function () {
    let fnameInitial = ""
    let lnameInitial = ""

    if (name) {
      let splitName = name.split(" ")
      fnameInitial = splitName[0][0] ?? ""
      lnameInitial = splitName[1][0] ?? ""
    }
    return { fnameInitial, lnameInitial }
  })()

  const menuList = [
    { label: "Profile", onClick: () => {}, Icon: <BiUser /> },
    { label: "Settings", onClick: () => {}, Icon: <FiSettings /> },
    { divider: true },
    {
      label: "Log out",
      onClick: () => {
        clearData()
        clearTokens()
        navigate(routeUrls.authentication.root, { replace: true })
      },
      Icon: <FiLogOut />,
    },
  ]

  return (
    <Menu>
      <MenuButton
        as={Box}
        rightIcon={<ChevronDownIcon />}
        _active={{
          backgroundColor: colors.backgroundContent,
          borderColor: colors.border,
        }}
        _hover={{
          backgroundColor: colors.backgroundContent,
          borderColor: colors.border,
        }}
        className="rounded-xl border border-transparent transition-all cursor-pointer w-full"
      >
        <Box
          bg={colors.backgroundContent}
          borderColor={colors.border}
          minW="full"
          w="full"
          className="flex items-center justify-between px-4 gap-2  py-2 rounded-xl"
        >
          <div className="flex items-center gap-3">
            <div className="h-8 w-8 rounded-full bg-green-400 flex items-center justify-center">
              <SharedText.extraSmall
                className="font-semibold"
                style={{ color: "#212121" }}
              >
                {fnameInitial} {lnameInitial}
              </SharedText.extraSmall>
            </div>
            <div className="flex flex-col gap-0">
              <SharedText.extraSmall className="font-semibold">
                {name}
              </SharedText.extraSmall>
              <SharedText.extraSmall
                style={{ color: "GrayText" }}
                className="font-semibold text-[11px]"
              >
                {getRole()}
              </SharedText.extraSmall>
            </div>
          </div>
          <FiChevronDown style={{ color: colors.textSub }} className="ml-2" />
        </Box>
      </MenuButton>
      <MenuList
        bg={colors.backgroundContent}
        borderColor={colors.border}
        p={2}
        className="flex flex-col gap-1 border"
      >
        {menuList.map(({ divider, label, onClick, Icon }, index) => {
          if (label)
            return (
              <MenuItem
                key={index}
                onClick={onClick}
                _hover={{
                  bg: colors.backgroundSidebar,
                  fontWeight: "semibold",
                }}
                bg={colors.backgroundContent}
                color={colors.textSub}
                className="rounded-lg text-sm flex items-center gap-2 transition-all"
              >
                {Icon}
                <Text>{label}</Text>
              </MenuItem>
            )
          if (divider) return <MenuDivider key={index} />
          return <></>
        })}
      </MenuList>
    </Menu>
  )
}

export default MobileProfile
