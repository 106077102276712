import { useGetThemeColors, useGetThemeData } from "~/stores/theme"

const SharedIconWrapper: React.FunctionComponent<
  {
    children: React.ReactNode
    showBackground?: boolean
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ children, showBackground = true, ...props }) => {
  const { border, textSub } = useGetThemeColors()
  const { themeMode } = useGetThemeData()
  return (
    <div
      {...props}
      style={{ color: textSub, borderColor: border, ...props.style }}
      className={`
        ${props.className}
        ${
          showBackground &&
          (themeMode === "light"
            ? "hover:bg-gray-300 hover:text-[#212121]"
            : "hover:bg-[#191919] hover:text-white")
        }
        h-8 w-8 rounded-full flex  border items-center justify-center cursor-pointer transition-all
    `}
    >
      {children}
    </div>
  )
}

export default SharedIconWrapper
