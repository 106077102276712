import { Divider, Tag } from "antd"
import SharedSidebarNavLink from "~/components/shared/compoents/navlink"
import SharedText from "~/components/shared/compoents/text"
import { useGetThemeColors } from "~/stores/theme"
import {
  ADMIN_SIDEBAR_MAIN_LINKS,
  ADMIN_SIDEBAR_MORE_LINKS,
} from "~/hooks/admin/sidebar-links"

const AdminSidebar = () => {
  const { backgroundContent, border } = useGetThemeColors()
  return (
    <div className=" w-full relative flex flex-col gap-2 max-h-[70vh] overflow-y-auto px-2 py-4 pb-16">
      {/* <div /> */}

      <div className="w-full flex flex-col gap-2">
        <Tag
          hidden
          style={{ backgroundColor: backgroundContent, borderColor: border }}
          className="capitalize text-xs w-fit border"
        >
          <SharedText.small>main menu</SharedText.small>
        </Tag>
        {ADMIN_SIDEBAR_MAIN_LINKS.map(({ Icon, Label, routePath }, index) => (
          <SharedSidebarNavLink key={index} {...{ Icon, Label, routePath }} />
        ))}
      </div>

      <Divider style={{ backgroundColor: border }} />

      <div className="w-full flex flex-col gap-2">
        <Tag
          hidden
          style={{ backgroundColor: backgroundContent, borderColor: border }}
          className="capitalize text-xs w-fit border"
        >
          <SharedText.small>system</SharedText.small>
        </Tag>
        {ADMIN_SIDEBAR_MORE_LINKS.map(({ Icon, Label, routePath }, index) => (
          <SharedSidebarNavLink key={index} {...{ Icon, Label, routePath }} />
        ))}
      </div>
    </div>
  )
}

export default AdminSidebar
