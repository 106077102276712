import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { useUserInfoStore } from "~/stores/user-data"
import { baseUrl } from "./api-caller"
import {
  clearTokens,
  getAccessToken,
  getRefreshToken,
  setAccessToken,
} from "./auth-token-utils"

const useTokenExpiration = () => {
  const intervalRef = useRef<any>()
  const { access, refresh, clearData, updateTokens } = useUserInfoStore()

  const jwtTokenObj:
    | {
        token_type: string
        exp: number
        iat: number
        jti: string
        user_id: string
      }
    | undefined = access
    ? JSON.parse(atob(String(access).split(".")[1]))
    : undefined

  /**
   * 'TOKEN_REFRESH_THRESHOLD'
   * epresents the time remaining on the access token
   * before considering it expired and triggering a refresh.
   */
  const TOKEN_REFRESH_THRESHOLD = 1 * 60 * 1000
  const EXPIRATION_TIME = jwtTokenObj?.exp ?? 0

  // ! for testing only
  // const TOKEN_REFRESH_THRESHOLD = 0.1 * 60 * 1000
  // const EXPIRATION_TIME = 0.15 * 60 * 1000

  const [isLoggedIn, set_isLoggedIn] = useState(false)
  const [restartCounter, set_restartCounter] = useState(0)

  useEffect(() => {
    let _access = getAccessToken()
    let _refresh = getRefreshToken()
    set_isLoggedIn(!!_access && !!_refresh)
  }, [])

  useEffect(() => {
    set_isLoggedIn(!!access && !!refresh)
  }, [access, refresh])

  useEffect(() => {
    if (isLoggedIn) {
      const expirationTime = new Date().getTime() + EXPIRATION_TIME
      const checkTokenExpiration = () => {
        const accessToken = getAccessToken()
        const refreshToken = getRefreshToken()
        if (accessToken && refreshToken) {
          const currentTime = new Date().getTime()
          const timeRemaining = expirationTime - currentTime

          if (timeRemaining <= TOKEN_REFRESH_THRESHOLD) {
            ;(async function () {
              try {
                const res = await axios.post<{ access: string }>(
                  `${baseUrl}/auth/token/refresh/`,
                  { refresh },
                )
                setAccessToken(res.data.access)
                updateTokens({
                  access: res.data.access,
                  refresh: refresh!,
                })
                set_restartCounter((prev) => prev + 1)
              } catch (error) {
                clearTokens()
                clearData()
                throw error
              }
            })()
          }
        }
      }

      intervalRef.current = setInterval(checkTokenExpiration, 1000) // Run the check every second
    } else {
      clearInterval(intervalRef.current)
    }

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [isLoggedIn, restartCounter])
}

export default useTokenExpiration
