export const VISITOR_DASHBOARD_ROOT = "/visitor"

const VISITOR_MODULE_ROUTES = {
  root: VISITOR_DASHBOARD_ROOT,
  live_session: `${VISITOR_DASHBOARD_ROOT}/trade-sessions/:sessionId/`,
  _live_session: (sessionId: string) =>
    `${VISITOR_DASHBOARD_ROOT}/trade-sessions/${sessionId}/`,
  trade_sessions: `${VISITOR_DASHBOARD_ROOT}/trade-sessions/`,
}

export default VISITOR_MODULE_ROUTES
