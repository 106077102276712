export const TRADING_DASHBOARD_ROOT = "/trading"

const TRADING_MODULE_ROUTES = {
  root: TRADING_DASHBOARD_ROOT,
  dashboard: `${TRADING_DASHBOARD_ROOT}/dashboard`,

  buyers: `${TRADING_DASHBOARD_ROOT}/buyers`,
  union_amcos_buyers_buyers_create: `${TRADING_DASHBOARD_ROOT}/buyers/create`,

  union_amcos_buyers: `${TRADING_DASHBOARD_ROOT}/union-amcos-buyers`,
  union_amcos_buyers_buyers: `${TRADING_DASHBOARD_ROOT}/union-amcos-buyers/buyers`,
  union_amcos_buyers_union: `${TRADING_DASHBOARD_ROOT}/union-amcos-buyers/union`,
  union_amcos_buyers_union_create: `${TRADING_DASHBOARD_ROOT}/union-amcos-buyers/union/create`,
  union_amcos_buyers_union_update: `${TRADING_DASHBOARD_ROOT}/union-amcos-buyers/amcos/update/:unionID/`,
  _union_amcos_buyers_union_update: (id: string) => {
    return `${TRADING_DASHBOARD_ROOT}/union-amcos-buyers/amcos/update/${id}/`
  },
  union_amcos_buyers_amcos: `${TRADING_DASHBOARD_ROOT}/union-amcos-buyers/amcos`,
  union_amcos_buyers_amcos_create: `${TRADING_DASHBOARD_ROOT}/union-amcos-buyers/amcos/create`,
  union_amcos_buyers_amcos_update: `${TRADING_DASHBOARD_ROOT}/union-amcos-buyers/amcos/update/:amcosID/`,
  _union_amcos_buyers_amcos_update: (id: string) => {
    return `${TRADING_DASHBOARD_ROOT}/union-amcos-buyers/amcos/update/${id}/`
  },

  trading: `${TRADING_DASHBOARD_ROOT}/trading`,
  trading_lots: `${TRADING_DASHBOARD_ROOT}/trading/lots`,
  trading_lots_upload_receipt: `${TRADING_DASHBOARD_ROOT}/trading/lots/create/upload-receipt`,
  trading_lots_upload_lot: `${TRADING_DASHBOARD_ROOT}/trading/lots/create/upload-lot`,
  trading_lots_create: `${TRADING_DASHBOARD_ROOT}/trading/lots/create`,
  trading_lots_amcos_storage_update: `${TRADING_DASHBOARD_ROOT}/trading/lots/amcos-storage/update/:lotID/`,
  _trading_lots_amcos_storage_update: (id: string) =>
    `${TRADING_DASHBOARD_ROOT}/trading/lots/amcos-storage/update/${id}/`,

  trading_catalogue: `${TRADING_DASHBOARD_ROOT}/trading/catalogue`,
  trading_catalogue_create: `${TRADING_DASHBOARD_ROOT}/trading/catalogue/create`,
  trading_catalogue_update: `${TRADING_DASHBOARD_ROOT}/trading/catalogue/update/:catalogueID/`,
  _trading_catalogue_update: (id: string) =>
    `${TRADING_DASHBOARD_ROOT}/trading/catalogue/update/${id}/`,

  trading_session: `${TRADING_DASHBOARD_ROOT}/trading/session`,
  trading_session_create: `${TRADING_DASHBOARD_ROOT}/trading/session/create`,
  trading_session_update: `${TRADING_DASHBOARD_ROOT}/trading/session/update/:tradeSessionID/`,
  _trading_session_update: (id: string) =>
    `${TRADING_DASHBOARD_ROOT}/trading/session/update/${id}/`,
  trading_session_live: `${TRADING_DASHBOARD_ROOT}/trading/session/live/:sessionId/`,
  _trading_session_live: (sessionId: string) =>
    `${TRADING_DASHBOARD_ROOT}/trading/session/live/${sessionId}/`,

  trading_results: `${TRADING_DASHBOARD_ROOT}/trading/results`,
  trading_results_2: `${TRADING_DASHBOARD_ROOT}/results`,
  trading_reports: `${TRADING_DASHBOARD_ROOT}/reports`,
  trading_result: `${TRADING_DASHBOARD_ROOT}/results/:sessionID`,
  _trading_result: (sessionID: string) =>
    `${TRADING_DASHBOARD_ROOT}/results/${sessionID}`,

  settings: `${TRADING_DASHBOARD_ROOT}/settings`,
  settings_area_levels: `${TRADING_DASHBOARD_ROOT}/settings/area-levels`,
  settings_area_levels_create: `${TRADING_DASHBOARD_ROOT}/settings/area-levels/create`,
  settings_area_levels_view: `${TRADING_DASHBOARD_ROOT}/settings/area-levels/view`,
  settings_organisations_types: `${TRADING_DASHBOARD_ROOT}/settings/organisation-types`,
  settings_organisations_types_view: `${TRADING_DASHBOARD_ROOT}/settings/organisation-types/view`,
  settings_organisations_types_create: `${TRADING_DASHBOARD_ROOT}/settings/organisation-types/create`,
  settings_organisations_types_edit: `${TRADING_DASHBOARD_ROOT}/settings/organisation-types/edit`,
  settings_banks: `${TRADING_DASHBOARD_ROOT}/settings/banks`,
  settings_banks_create: `${TRADING_DASHBOARD_ROOT}/settings/banks/create`,
  settings_seasons: `${TRADING_DASHBOARD_ROOT}/settings/seasons`,
  settings_seasons_create: `${TRADING_DASHBOARD_ROOT}/settings/seasons/create`,
  settings_crops: `${TRADING_DASHBOARD_ROOT}/settings/crops`,
  settings_crops_create: `${TRADING_DASHBOARD_ROOT}/settings/crops/create`,
  settings_crop_types: `${TRADING_DASHBOARD_ROOT}/settings/crop-types`,
  settings_crop_types_create: `${TRADING_DASHBOARD_ROOT}/settings/crop-types/create`,
  settings_grades: `${TRADING_DASHBOARD_ROOT}/settings/grades`,
  settings_grades_create: `${TRADING_DASHBOARD_ROOT}/settings/grades/create`,
  settings_packaging: `${TRADING_DASHBOARD_ROOT}/settings/packaging`,
  settings_packaging_create: `${TRADING_DASHBOARD_ROOT}/settings/packaging/create`,
  settings_units: `${TRADING_DASHBOARD_ROOT}/settings/units`,
  settings_units_create: `${TRADING_DASHBOARD_ROOT}/settings/units/create`,
  settings_catalogue_type: `${TRADING_DASHBOARD_ROOT}/settings/catalogue-type`,
  settings_catalogue_type_create: `${TRADING_DASHBOARD_ROOT}/settings/catalogue-type/create`,
  settings_warehouse_type: `${TRADING_DASHBOARD_ROOT}/settings/warehouse-type`,
  settings_warehouse_type_create: `${TRADING_DASHBOARD_ROOT}/settings/warehouse-type/create`,

  help_support: `${TRADING_DASHBOARD_ROOT}/help-support`,
}

export default TRADING_MODULE_ROUTES
