import Cookies from "js-cookie"

const ACCESS_TOKEN_KEY = "access"
const REFRESH_TOKEN_KEY = "refresh"

export const setAccessToken = (token: string) => {
  Cookies.set(ACCESS_TOKEN_KEY, token)
}

export const getAccessToken = (): string | undefined => {
  return Cookies.get(ACCESS_TOKEN_KEY)
}

export const setRefreshToken = (token: string) => {
  Cookies.set(REFRESH_TOKEN_KEY, token)
}

export const getRefreshToken = (): string | undefined => {
  return Cookies.get(REFRESH_TOKEN_KEY)
}

export const clearTokens = () => {
  Cookies.remove(ACCESS_TOKEN_KEY)
  Cookies.remove(REFRESH_TOKEN_KEY)
}
