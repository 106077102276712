import {
  AiOutlineCloud,
  AiOutlineExclamation,
  AiOutlineSetting,
  AiOutlineUnorderedList,
} from "react-icons/ai"
import { BiCategory, BiPackage } from "react-icons/bi"
import { BsBank, BsBuildingAdd } from "react-icons/bs"
import { FiHome, FiUserCheck } from "react-icons/fi"
import { GiThreeLeaves } from "react-icons/gi"
import { HiOutlineDocumentDuplicate } from "react-icons/hi"
import { TbListDetails, TbRulerMeasure } from "react-icons/tb"
import { TiFlowMerge } from "react-icons/ti"

import { FaWarehouse } from "react-icons/fa"
import routeUrls from "~/routing/routes"

export type TADMIN_SIDEBAR_LINKS = {
  Icon: React.ReactNode
  Label: React.ReactNode
  routePath: string
}[]

export const ADMIN_SIDEBAR_MAIN_LINKS: TADMIN_SIDEBAR_LINKS = [
  {
    Icon: <FiHome />,
    Label: "home",
    routePath: routeUrls.adminDashboard.dashboard,
  },
  {
    Label: "Sellers",
    routePath: routeUrls.adminDashboard.union_amcos_farmers,
    Icon: <BiCategory />,
  },
  {
    Label: "Institutions",
    routePath: routeUrls.adminDashboard.organisations_buyers,
    Icon: <BsBuildingAdd />,
  },
  {
    Label: "Warehouse",
    routePath: routeUrls.adminDashboard.warehouse,
    Icon: <FaWarehouse />,
  },
  {
    Label: "System User",
    routePath: routeUrls.adminDashboard.system_user,
    Icon: <FiUserCheck />,
  },
  {
    Label: "Reports",
    routePath: routeUrls.adminDashboard.reports,
    Icon: <HiOutlineDocumentDuplicate />,
  },
]

export const ADMIN_SIDEBAR_MORE_LINKS: TADMIN_SIDEBAR_LINKS = [
  {
    Icon: <AiOutlineSetting />,
    Label: "configurations",
    routePath: routeUrls.adminDashboard.settings,
  },
  {
    Icon: <AiOutlineExclamation />,
    Label: "help and support",
    routePath: routeUrls.adminDashboard.help_support,
  },
]

export const ADMIN_SETTINGS_SIDEBAR_MAIN_LINKS: TADMIN_SIDEBAR_LINKS = [
  {
    Icon: <TiFlowMerge />,
    Label: "area levels",
    routePath: routeUrls.adminDashboard.settings_area_levels,
  },
  // {
  //   Label: "Organisations types",
  //   routePath: routeUrls.adminDashboard.settings_organisations_types,
  //   Icon: <BsBuildingAdd />,
  // },
  {
    Label: "Banks",
    routePath: routeUrls.adminDashboard.settings_banks,
    Icon: <BsBank />,
  },
  {
    Label: "Seasons",
    routePath: routeUrls.adminDashboard.settings_seasons,
    Icon: <AiOutlineCloud />,
  },
  {
    Label: "Crops",
    routePath: routeUrls.adminDashboard.settings_crops,
    Icon: <GiThreeLeaves />,
  },
  {
    Label: "Crop Types",
    routePath: routeUrls.adminDashboard.settings_crop_types,
    Icon: <AiOutlineUnorderedList />,
  },
  {
    Label: "Grades",
    routePath: routeUrls.adminDashboard.settings_grades,
    Icon: <TbListDetails />,
  },
  {
    Label: "Packaging",
    routePath: routeUrls.adminDashboard.settings_packaging,
    Icon: <BiPackage />,
  },
  {
    Label: "Units",
    routePath: routeUrls.adminDashboard.settings_units,
    Icon: <TbRulerMeasure />,
  },
]
