export const FINANCE_DASHBOARD_ROOT = "/finance"

const FINANCE_MODULE_ROUTES = {
  root: FINANCE_DASHBOARD_ROOT,
  home: `${FINANCE_DASHBOARD_ROOT}/home`,
  finance_funds: `${FINANCE_DASHBOARD_ROOT}/funds`,
  finance_funds_security_deposits: `${FINANCE_DASHBOARD_ROOT}/funds/security-deposits/:companyID/`,
  _finance_funds_security_deposits: (id: string) =>
    `${FINANCE_DASHBOARD_ROOT}/funds/security-deposits/${id}/`,
  finance_funds_security_deposits_add: `${FINANCE_DASHBOARD_ROOT}/funds/security-deposits/:companyID/:companyCropID/:cropName/`,
  _finance_funds_security_deposits_add: (
    companyID: string,
    companyCropID: string,
    cropName: string,
  ) =>
    `${FINANCE_DASHBOARD_ROOT}/funds/security-deposits/${companyID}/${companyCropID}/${cropName}/`,

  crop_board: `${FINANCE_DASHBOARD_ROOT}/crop-board`,
  crop_board_details: `${FINANCE_DASHBOARD_ROOT}/crop-board/:companyName/:companyID`,
  _crop_board_details: (name: string, id: string) =>
    `${FINANCE_DASHBOARD_ROOT}/crop-board/${name}/${id}`,
  trade_results: `${FINANCE_DASHBOARD_ROOT}/trade-results`,

  trade_results_by_session: `${FINANCE_DASHBOARD_ROOT}/trade-results/:sessionID`,
  _trade_results_by_session: (sessionID: string) =>
    `${FINANCE_DASHBOARD_ROOT}/trade-results/${sessionID}`,

  invoices: `${FINANCE_DASHBOARD_ROOT}/invoices`,
  invoices_preview: `${FINANCE_DASHBOARD_ROOT}/invoices/preview/:invoiceID/`,
  _invoices_preview: (id: string) =>
    `${FINANCE_DASHBOARD_ROOT}/invoices/preview/${id}/`,

  funds: `${FINANCE_DASHBOARD_ROOT}/funds`,
  reports: `${FINANCE_DASHBOARD_ROOT}/reports`,

  configurations: `${FINANCE_DASHBOARD_ROOT}/configurations`,

  configurations_beneficiaries: `${FINANCE_DASHBOARD_ROOT}/configurations/beneficiaries`,
  configurations_beneficiaries_create: `${FINANCE_DASHBOARD_ROOT}/configurations/beneficiaries/create`,
  configurations_beneficiaries_update: `${FINANCE_DASHBOARD_ROOT}/configurations/beneficiaries/update/:ID`,
  _configurations_beneficiaries_update: (ID: string) =>
    `${FINANCE_DASHBOARD_ROOT}/configurations/beneficiaries/update/${ID}`,

  configurations_signatory: `${FINANCE_DASHBOARD_ROOT}/configurations/signatory`,
  configurations_signatory_create: `${FINANCE_DASHBOARD_ROOT}/configurations/signatory/create`,
  configurations_signatory_update: `${FINANCE_DASHBOARD_ROOT}/configurations/signatory/update/:signatoryID`,
  _configurations_signatory_update: (signatoryID: string) =>
    `${FINANCE_DASHBOARD_ROOT}/configurations/signatory/update/${signatoryID}`,

  configurations_rates_charges: `${FINANCE_DASHBOARD_ROOT}/configurations/rates-charges`,
  configurations_rates_charges_create: `${FINANCE_DASHBOARD_ROOT}/configurations/rates-charges/create`,
  configurations_rates_charges_update: `${FINANCE_DASHBOARD_ROOT}/configurations/rates-charges/update/:ID/:chargeKind`,
  _configurations_rates_charges_update: (
    ID: string,
    chargeKind: "cess" | "rate",
  ) =>
    `${FINANCE_DASHBOARD_ROOT}/configurations/rates-charges/update/${ID}/${chargeKind}/`,

  configurations_bank_mmo: `${FINANCE_DASHBOARD_ROOT}/configurations/bank-mmo`,
  configurations_bank_mmo_create: `${FINANCE_DASHBOARD_ROOT}/configurations/bank-mmo/create`,
  configurations_bank_mmo_update: `${FINANCE_DASHBOARD_ROOT}/configurations/bank-mmo/update/:bank_mno_ID`,
  _configurations_bank_mmo_update: (bank_mno_ID: string) =>
    `${FINANCE_DASHBOARD_ROOT}/configurations/bank-mmo/update/${bank_mno_ID}`,

  configurations_settlement_banks: `${FINANCE_DASHBOARD_ROOT}/configurations/settlement-banks`,
  configurations_settlement_banks_create: `${FINANCE_DASHBOARD_ROOT}/configurations/settlement-banks/create`,
  configurations_settlement_banks_update: `${FINANCE_DASHBOARD_ROOT}/configurations/settlement-banks/update/:bankID`,
  _configurations_settlement_banks_update: (bankID: string) =>
    `${FINANCE_DASHBOARD_ROOT}/configurations/settlement-banks/update/${bankID}`,
}

export default FINANCE_MODULE_ROUTES
