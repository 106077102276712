export const BUYER_DASHBOARD_ROOT = "/buyer"

const BUYER_MODULE_ROUTES = {
  root: BUYER_DASHBOARD_ROOT,
  home: `${BUYER_DASHBOARD_ROOT}/home`,
  trading: `${BUYER_DASHBOARD_ROOT}/trading`,
  trading_lots: `${BUYER_DASHBOARD_ROOT}/trading/lots/:sessionId/`,
  _trading_lots: (id: string) => `${BUYER_DASHBOARD_ROOT}/trading/lots/${id}/`,
  trade_financials: `${BUYER_DASHBOARD_ROOT}/trade-financials`,
  reports: `${BUYER_DASHBOARD_ROOT}/reports`,
  invoice: `${BUYER_DASHBOARD_ROOT}/invoices/:invoiceID`,
  _invoice: (id: string) => `${BUYER_DASHBOARD_ROOT}/invoices/${id}`,
  configurations: `${BUYER_DASHBOARD_ROOT}/configurations`,
  tradeResults: `${BUYER_DASHBOARD_ROOT}/trade-results`,
  trading_results_session: `${BUYER_DASHBOARD_ROOT}/trade-results/:sessionID/`,
  _trading_results_session: (sessionID: string) =>
    `${BUYER_DASHBOARD_ROOT}/trade-results/${sessionID}/`,
}

export default BUYER_MODULE_ROUTES
