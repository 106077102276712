export const UNION_REP_DASHBOARD_ROOT = "/union-rep"

const UNION_REP_MODULE_ROUTES = {
  root: UNION_REP_DASHBOARD_ROOT,
  dashboard: `${UNION_REP_DASHBOARD_ROOT}/dashboard`,
  sellers: `${UNION_REP_DASHBOARD_ROOT}/sellers`,
  trading: `${UNION_REP_DASHBOARD_ROOT}/trading`,
  trading_reports: `${UNION_REP_DASHBOARD_ROOT}/reports`,
}

export default UNION_REP_MODULE_ROUTES
