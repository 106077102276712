import { Text } from "@chakra-ui/react"
import { BsArrowRightShort } from "react-icons/bs"
import { NavLink, useLocation } from "react-router-dom"
import { useGetThemeData } from "~/stores/theme"

export interface ISharedSidebarNavLink {
  routePath: string
  Icon: React.ReactNode
  Label: React.ReactNode
  showArrow?: boolean
}

const SharedSidebarNavLink: React.FunctionComponent<ISharedSidebarNavLink> = ({
  Icon,
  Label,
  routePath,
  showArrow,
}) => {
  const { themeMode, colors } = useGetThemeData()
  const colorValues = colors(themeMode)
  const { pathname } = useLocation()

  return (
    <NavLink
      to={routePath}
      end
      style={({ isActive, isPending }) => {
        if (isActive || pathname.startsWith(routePath)) {
          return {
            backgroundColor:
              themeMode === "light" ? "#E4E3E5" : colorValues.backgroundContent,
            borderColor: colorValues.brandPrimary,
            fontWeight: "bold",
          }
        }

        if (isPending) {
          return {
            color: colorValues.textSub,
          }
        }
        return {
          fontWeight: "normal",
          color: colorValues.textSub,
        }
      }}
      className={({ isActive, isPending }) => `
                          ${themeMode === "light" && "hover:bg-[#E4E3E5]"}
                          ${themeMode === "dark" && "hover:bg-[#191919]"}
                          flex items-center border-[0.5px] border-transparent gap-4 justify-between group px-3 py-2 rounded-lg transition-all
                      `}
    >
      <div className="flex items-center gap-2">
        {Icon && (
          <div className="h-6 w-6 rounded-lg flex items-center justify-center">
            <p style={{ color: colorValues.textSub }} className="text-xl">
              {Icon}
            </p>
          </div>
        )}
        <Text
          style={{ color: colorValues.textSub }}
          className="text-sm whitespace-nowrap capitalize"
        >
          {Label}
        </Text>
      </div>
      {showArrow && (
        <p style={{ color: colorValues.textSub }} className="">
          <BsArrowRightShort />
        </p>
      )}
    </NavLink>
  )
}

export default SharedSidebarNavLink
