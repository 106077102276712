import { Box } from "@chakra-ui/layout"
import { Tooltip } from "@chakra-ui/tooltip"
import { RiNotification3Line } from "react-icons/ri"
import SharedIconWrapper from "~/components/shared/compoents/icon-wrapper"
import useThemeStore from "~/stores/theme"

const AdminLayout_Desktop_TopBar_ActionIcons: React.FunctionComponent = () => {
  const { themeMode, changeTheme } = useThemeStore()
  const isLightThemeMode = themeMode === "light"
  return (
    <div className="flex items-center justify-center gap-4 w-fit">
      <Tooltip
        hidden
        hasArrow
        label="Notifications"
        bg="gray.300"
        color="black"
      >
        <Box hidden w="fit-content">
          <SharedIconWrapper>
            <RiNotification3Line />
          </SharedIconWrapper>
        </Box>
      </Tooltip>
    </div>
  )
}

export default AdminLayout_Desktop_TopBar_ActionIcons
