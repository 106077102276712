import {
  Spinner,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react"
import { useGetThemeColors } from "~/stores/theme"
import SharedLoading from "./loading"

export interface IDashboardSummaryBlock {
  Icon?: React.ReactNode
  valueExtraLabel?: React.ReactNode
  value: React.ReactNode
  subValue?: React.ReactNode
  label: React.ReactNode
  onClick?: () => void
  loading?: boolean
}

const DashboardSummaryBlock: React.FunctionComponent<IDashboardSummaryBlock> = (
  props,
) => {
  const {
    backgroundSidebar,
    border,
    brandPrimary,
    backgroundContent,
    textSub,
  } = useGetThemeColors()
  return (
    <div
      // onClick={props.onClick}
      style={{ backgroundColor: backgroundSidebar, borderColor: border }}
      className={`h-fit grow border-2 border-gray-500 rounded-lg flex flex-col justify-center gap-2 p-4 ${
        props.onClick && "cursor-pointerr"
      }`}
    >
      <Stat color={textSub}>
        <StatLabel className="uppercase">{props.label}</StatLabel>
        {props.loading ? (
          <Spinner mt={3} size="sm" />
        ) : (
          <>
            <div className="flex items-center gap-2">
              <StatNumber fontSize={"3xl"}>{props.value}</StatNumber>
              {props.subValue && (
                <StatHelpText
                  color={brandPrimary}
                  bg={backgroundContent}
                  borderColor={border}
                  className="flex items-center w-fit px-2 py-1 rounded-lg border"
                >
                  {props.subValue}
                </StatHelpText>
              )}
            </div>
            {(props.Icon || props.valueExtraLabel) && (
              <StatHelpText
                color={brandPrimary}
                bg={backgroundContent}
                borderColor={border}
                className="flex items-center w-fit px-2 py-1 rounded-lg border mt-1"
              >
                {props.Icon}
                {props.valueExtraLabel}
              </StatHelpText>
            )}
          </>
        )}
      </Stat>
    </div>
  )
}

export default DashboardSummaryBlock
