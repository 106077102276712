export const CROP_BOARD_DASHBOARD_ROOT = "/crop-board"

const CROP_BOARD_MODULE_ROUTES = {
  root: CROP_BOARD_DASHBOARD_ROOT,
  dashboard: `${CROP_BOARD_DASHBOARD_ROOT}/dashboard`,
  buyers: `${CROP_BOARD_DASHBOARD_ROOT}/buyers`,
  trading: `${CROP_BOARD_DASHBOARD_ROOT}/trading`,
  trading_results: `${CROP_BOARD_DASHBOARD_ROOT}/trade-results`,
  trading_result: `${CROP_BOARD_DASHBOARD_ROOT}/trade-results/:sessionID`,
  _trading_result: (id: string) =>
    `${CROP_BOARD_DASHBOARD_ROOT}/trade-results/${id}`,
  trading_reports: `${CROP_BOARD_DASHBOARD_ROOT}/reports`,
  invoices: `${CROP_BOARD_DASHBOARD_ROOT}/invoices`,
  invoice: `${CROP_BOARD_DASHBOARD_ROOT}/invoices/:invoiceID`,
  _invoice: (id: string) => `${CROP_BOARD_DASHBOARD_ROOT}/invoices/${id}`,
}

export default CROP_BOARD_MODULE_ROUTES
