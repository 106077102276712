import ADMIN_MODULE_ROUTES from "./admin"
import AUTHENTICATION_MODULE_ROUTES from "./authentication"
import TRADING_MODULE_ROUTES from "./trading"
import FINANCE_MODULE_ROUTES from "./finance"
import CEO_MODULE_ROUTES from "./ceo"
import ORGANISATION_REP_MODULE_ROUTES from "./organisation-rep"
import BUYER_MODULE_ROUTES from "./buyer"
import VISITOR_MODULE_ROUTES from "./visitor"
import CROP_BOARD_MODULE_ROUTES from "./crop-board"
import UNION_REP_MODULE_ROUTES from "./union-rep"

const routeUrls = {
  authentication: { ...AUTHENTICATION_MODULE_ROUTES },
  adminDashboard: { ...ADMIN_MODULE_ROUTES },
  tradingDashboard: { ...TRADING_MODULE_ROUTES },
  financeDashboard: { ...FINANCE_MODULE_ROUTES },
  ceoDashboard: { ...CEO_MODULE_ROUTES },
  organisationRepDashboard: { ...ORGANISATION_REP_MODULE_ROUTES },
  buyerDashboard: { ...BUYER_MODULE_ROUTES },
  visitorDashboard: { ...VISITOR_MODULE_ROUTES },
  cropBoardDashboard: { ...CROP_BOARD_MODULE_ROUTES },
  unionRepDashboard: { ...UNION_REP_MODULE_ROUTES },
}

export default routeUrls
