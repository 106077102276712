export const ADMIN_DASHBOARD_ROOT = "/admin"

const ADMIN_MODULE_ROUTES = {
  root: ADMIN_DASHBOARD_ROOT,
  dashboard: `${ADMIN_DASHBOARD_ROOT}/dashboard`,

  area_levels: `${ADMIN_DASHBOARD_ROOT}/area-levels`,
  area_levels_create: `${ADMIN_DASHBOARD_ROOT}/area-levels/create`,

  union_amcos_farmers: `${ADMIN_DASHBOARD_ROOT}/union-amcos-farmers`,
  union_amcos_farmers_UNION: `${ADMIN_DASHBOARD_ROOT}/union-amcos-farmers/union`,
  union_amcos_farmers_AMCOS: `${ADMIN_DASHBOARD_ROOT}/union-amcos-farmers/amcos`,
  union_amcos_farmers_FARMERS: `${ADMIN_DASHBOARD_ROOT}/union-amcos-farmers/farmers`,
  union_amcos_farmers_UNION_add: `${ADMIN_DASHBOARD_ROOT}/union-amcos-farmers/union/add`,
  union_amcos_farmers_AMCOS_add: `${ADMIN_DASHBOARD_ROOT}/union-amcos-farmers/amcos/add`,
  union_amcos_farmers_FARMERS_add: `${ADMIN_DASHBOARD_ROOT}/union-amcos-farmers/farmers/add`,
  union_amcos_farmers_UNION_update: `${ADMIN_DASHBOARD_ROOT}/union-amcos-farmers/union/update/:unionID/`,
  _union_amcos_farmers_UNION_update: (id: string) => {
    return `${ADMIN_DASHBOARD_ROOT}/union-amcos-farmers/union/update/${id}/`
  },
  union_amcos_farmers_AMCOS_update: `${ADMIN_DASHBOARD_ROOT}/union-amcos-farmers/amcos/update/:amcosID/`,
  _union_amcos_farmers_AMCOS_update: (id: string) => {
    return `${ADMIN_DASHBOARD_ROOT}/union-amcos-farmers/amcos/update/${id}/`
  },

  organisations_buyers: `${ADMIN_DASHBOARD_ROOT}/organisations-buyers`,
  organisations_buyers_add_buyer: `${ADMIN_DASHBOARD_ROOT}/organisations-buyers/create`,
  organisations_buyers_add_other: `${ADMIN_DASHBOARD_ROOT}/organisations-buyers/add-other`,
  organisations_buyers_update_company: `${ADMIN_DASHBOARD_ROOT}/organisations-buyers/update/:companyID/`,
  _organisations_buyers_update_company: (id: string) =>
    `${ADMIN_DASHBOARD_ROOT}/organisations-buyers/update/${id}/`,

  warehouse: `${ADMIN_DASHBOARD_ROOT}/warehouses`,
  warehouse_create: `${ADMIN_DASHBOARD_ROOT}/warehouses/create`,
  warehouse_update: `${ADMIN_DASHBOARD_ROOT}/warehouses/update/:warehouseID/`,
  _warehouse_update: (id: string) =>
    `${ADMIN_DASHBOARD_ROOT}/warehouses/update/${id}/`,

  system_user: `${ADMIN_DASHBOARD_ROOT}/system-user`,
  system_user_add: `${ADMIN_DASHBOARD_ROOT}/system-user/add`,

  statistics: `${ADMIN_DASHBOARD_ROOT}/statistics`,
  reports: `${ADMIN_DASHBOARD_ROOT}/reports`,

  settings: `${ADMIN_DASHBOARD_ROOT}/settings`,
  settings_area_levels: `${ADMIN_DASHBOARD_ROOT}/settings/area-levels`,
  settings_area_levels_create: `${ADMIN_DASHBOARD_ROOT}/settings/area-levels/create`,
  settings_area_levels_view: `${ADMIN_DASHBOARD_ROOT}/settings/area-levels/view`,
  settings_organisations_types: `${ADMIN_DASHBOARD_ROOT}/settings/organisation-types`,
  settings_organisations_types_view: `${ADMIN_DASHBOARD_ROOT}/settings/organisation-types/view`,
  settings_organisations_types_create: `${ADMIN_DASHBOARD_ROOT}/settings/organisation-types/create`,
  settings_organisations_types_edit: `${ADMIN_DASHBOARD_ROOT}/settings/organisation-types/edit`,
  settings_banks: `${ADMIN_DASHBOARD_ROOT}/settings/banks`,
  settings_banks_create: `${ADMIN_DASHBOARD_ROOT}/settings/banks/create`,
  settings_seasons: `${ADMIN_DASHBOARD_ROOT}/settings/seasons`,
  settings_seasons_create: `${ADMIN_DASHBOARD_ROOT}/settings/seasons/create`,
  settings_crops: `${ADMIN_DASHBOARD_ROOT}/settings/crops`,
  settings_crops_create: `${ADMIN_DASHBOARD_ROOT}/settings/crops/create`,
  settings_crop_types: `${ADMIN_DASHBOARD_ROOT}/settings/crop-types`,
  settings_crop_types_create: `${ADMIN_DASHBOARD_ROOT}/settings/crop-types/create`,
  settings_grades: `${ADMIN_DASHBOARD_ROOT}/settings/grades`,
  settings_grades_create: `${ADMIN_DASHBOARD_ROOT}/settings/grades/create`,
  settings_packaging: `${ADMIN_DASHBOARD_ROOT}/settings/packaging`,
  settings_packaging_create: `${ADMIN_DASHBOARD_ROOT}/settings/packaging/create`,
  settings_units: `${ADMIN_DASHBOARD_ROOT}/settings/units`,
  settings_units_create: `${ADMIN_DASHBOARD_ROOT}/settings/units/create`,
  settings_catalogue_type: `${ADMIN_DASHBOARD_ROOT}/settings/catalogue-type`,
  settings_catalogue_type_create: `${ADMIN_DASHBOARD_ROOT}/settings/catalogue-type/create`,
  settings_warehouse_type: `${ADMIN_DASHBOARD_ROOT}/settings/warehouse-type`,
  settings_warehouse_type_create: `${ADMIN_DASHBOARD_ROOT}/settings/warehouse-type/create`,

  help_support: `${ADMIN_DASHBOARD_ROOT}/help-support`,
}

export default ADMIN_MODULE_ROUTES
