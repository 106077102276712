import { ReactNode } from "react"

import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react"
import { GiHamburgerMenu } from "react-icons/gi"
import { HiOutlineMenuAlt2 } from "react-icons/hi"
import { MdClear } from "react-icons/md"
import { useLocation } from "react-router-dom"
import useThemeStore, { useGetThemeColors } from "~/stores/theme"
import Logo from "../../compoents/logo"
import DesktopProfile from "./components/desktop-profile"
import MobileProfile from "./components/mobile-profile"
import AdminLayout_Desktop_TopBar_ActionIcons from "./components/user-profile"
import SharedFooter from "../../compoents/footer"

export interface IDashboardLayout {
  Sidebar: ReactNode
  Contents: ReactNode
}

const DashboardLayout = (props: IDashboardLayout) => {
  const { pathname } = useLocation()
  const isSettingsPage = pathname.includes("admin/settings")

  const { themeMode, colors, changeTheme } = useThemeStore()
  const sidebarBg = colors(themeMode).backgroundSidebar
  const { backgroundContent, border, textSub, backgroundSidebar } =
    useGetThemeColors()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: menuOpen,
    onOpen: onOpenMenu,
    onClose: onCloseMenu,
    onToggle: onToggleMenu,
  } = useDisclosure()
  return (
    <>
      <div className="grow relative flex flex-col transition-all w-full min-h-screen">
        <div className="flex flex-col min-h-[60px] h-[60px] border-b-2 z-50 w-full">
          <div className="top-0 right-0 left-0 max-w-[1300pxx] w-full self-center sticky bg-transparent h-full flex items-center justify-between gap-5 px-5">
            <div className="flex items-center gap-4">
              <div
                onClick={onToggleMenu}
                className="h-8 w-8 rounded-sm flex items-center justify-center gap-2 cursor-pointer"
              >
                {!menuOpen ? (
                  <GiHamburgerMenu />
                ) : (
                  <MdClear className="text-xl" />
                )}
              </div>
              <Tooltip
                hidden
                hasArrow
                label={"Main menu"}
                bg="gray.300"
                color="black"
                placement="bottom-start"
              >
                <Box hidden maxW="fit-content" w="fit-content">
                  <HiOutlineMenuAlt2
                    style={{ color: textSub }}
                    className="cursor-pointer text-xl"
                    onClick={onOpen}
                  />
                </Box>
              </Tooltip>
              <Logo className="h-10" px-2 />
            </div>

            <div className="hidden md:flex items-center gap-4">
              <AdminLayout_Desktop_TopBar_ActionIcons />
              <DesktopProfile />
            </div>
          </div>
        </div>

        <Drawer placement={"left"} onClose={onClose} isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent
            style={{ backgroundColor: sidebarBg, borderColor: border }}
            className="relative"
          >
            <DrawerHeader
              borderBottomColor={border}
              borderBottomWidth="1px"
              className="flex flex-col gap-2 items-center"
            >
              <Logo className="h-12" px-2 />
            </DrawerHeader>
            <DrawerBody>
              {props.Sidebar}

              <div className="md:hidden flex flex-col items-center gap-4 px-4 grow absolute left-0 right-0 bottom-4">
                <AdminLayout_Desktop_TopBar_ActionIcons />
                <MobileProfile />
              </div>
            </DrawerBody>
          </DrawerContent>
        </Drawer>

        <div
          className={`grid ${
            menuOpen ? "grid-cols-[250px_1fr]" : "grid-cols-1"
          } min-h-screen`}
        >
          <div
            className={`h-full bg-gray-50 w-[250px] z-50 ${
              menuOpen ? "flex" : "hidden"
            } flex-col grow overflow-y-auto relative border-r`}
          >
            {props.Sidebar}

            <div className="md:hidden flex flex-col items-center gap-4 px-4 grow absolute left-0 right-0 bottom-4">
              <AdminLayout_Desktop_TopBar_ActionIcons />
              <MobileProfile />
            </div>
          </div>

          <div
            className={`${
              menuOpen ? "w-full p-4" : "p-8"
            } h-[calc(100vh-60px)]d w-full bg-white z-10 flex flex-col gap-5 grow overflow-y-auto`}
          >
            {props.Contents}
            <br />
            <br />
          </div>
        </div>
      </div>
      <SharedFooter />
    </>
  )
}

export default DashboardLayout
