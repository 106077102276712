import { create } from "zustand"
import { persist } from "zustand/middleware"

export enum USER_ROLES {
  admin = 1,
  tradeOfficer = 2,
  fincanceOfficer = 3,
  ceo = 4,
  organisationRep = 5,
  buyer = 6,
  union_rep = 7,
  crop_board = 8,
  visitor = 9,
}

export interface IUserDataValues {
  id: number | null
  name: string | null
  username: string | null
  email: string | null
  password: string | null
  access: string | null
  refresh: string | null
  role: USER_ROLES | null
  organisation: number | null
  company?: string
  union?: string
  can_generate_invoice?: boolean
  is_buyer_admin?: boolean
}

const initialUserDataValues: IUserDataValues = {
  access: null,
  refresh: null,
  email: null,
  name: null,
  organisation: null,
  password: null,
  role: null,
  id: null,
  username: null,
  company: undefined,
}

export interface IUserData extends IUserDataValues {
  // setters
  updateTokens: (props: { access: string; refresh: string }) => void
  setUserDataValues: (values: IUserDataValues) => void
  clearData: () => void
  getRole: (num?: number) => string
  setRole(role: USER_ROLES): void
}

const createdStore = create<IUserData>()

export const useUserInfoStore = createdStore(
  persist(
    (set, get) => {
      return {
        // values
        ...initialUserDataValues,

        // setters
        updateTokens: ({ access, refresh }) => {
          set(() => ({ refresh, access }))
        },
        setUserDataValues: (values) => {
          set(() => ({ ...values }))
        },
        setRole(role) {
          set((state) => ({ ...state, role }))
        },
        clearData: () => {
          set(() => ({ ...initialUserDataValues }))
        },
        getRole: (num) => {
          return (function () {
            const currentRole = num ?? get().role
            if (currentRole) {
              switch (currentRole) {
                case USER_ROLES.admin:
                  return "Admin"

                case USER_ROLES.tradeOfficer:
                  return "Trade officer"

                case USER_ROLES.fincanceOfficer:
                  return "Finance Officer"

                case USER_ROLES.ceo:
                  return "CEO"

                case USER_ROLES.organisationRep:
                  return "Organisation Rep"

                case USER_ROLES.buyer:
                  return "Buyer"

                case USER_ROLES.visitor:
                  return "Visitor"

                case USER_ROLES.crop_board:
                  return "Crop Board Rep."

                case USER_ROLES.union_rep:
                  return "Union Rep."

                default:
                  return "n/a"
              }
            }
            return "n/a"
          })()
        },
      }
    },
    { name: "user-info-store" },
  ),
)

export const useTokens = () => {
  const { access, refresh } = useUserInfoStore()
  return { access, refresh }
}

export const tokensUpdate = () => {
  const { updateTokens } = useUserInfoStore()
  return updateTokens
}

export const userDataSetter = () => {
  const { setUserDataValues } = useUserInfoStore()
  return setUserDataValues
}

export const getUserData = () => {
  const { email, name, organisation, password, role, id, username, getRole } =
    useUserInfoStore()
  return { email, name, organisation, password, role, id, username, getRole }
}
