import { Text } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import DashboardSummaryBlock from "~/components/shared/compoents/dashboard-summary-block"
import SharedLoading from "~/components/shared/compoents/loading"
import SharedText from "~/components/shared/compoents/text"
import WelcomeBlock from "~/components/shared/compoents/welcome-block"
import useIsAdmin from "~/hooks/authentication/use-is-admin"
import adminSummaryService, { IAdminSummary } from "~/_services/admins/summary"

const AdminDashboard = () => {
  useIsAdmin()

  const navigate = useNavigate()

  const [data, setData] = useState<IAdminSummary | undefined>()

  const [loading, setLoading] = useState(false)
  useEffect(() => {
    ;(async function () {
      try {
        setLoading(true)
        const res = await adminSummaryService()
        setData(res.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        throw error
      }
    })()
  }, [])

  if (loading) return <SharedLoading />

  return (
    <div className="flex flex-col gap-8">
      <SharedText.chakraTitle title="Home" size="lg" />

      <WelcomeBlock />

      <div className="flex flex-col gap-2">
        <Text fontSize="sm">Crops, crop types & grades</Text>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full">
          <DashboardSummaryBlock label="Registered Crops" value={data?.crop} />

          <DashboardSummaryBlock
            label="Registered Crop Types"
            value={data?.crop_types}
          />

          <DashboardSummaryBlock
            label="Registered Crop Grades"
            value={data?.grades}
          />
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <Text fontSize="sm">Sellers</Text>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full">
          <DashboardSummaryBlock
            label="Registered UNIONS"
            value={data?.union}
          />
          <DashboardSummaryBlock label="Registered AMCOS" value={data?.amcos} />
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <Text fontSize="sm">Institutions</Text>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full">
          <DashboardSummaryBlock
            label="Total Buyers"
            value={data?.institution?.buyer}
          />
          <DashboardSummaryBlock
            label="Total Organisations"
            value={data?.institution.organization}
          />
          <DashboardSummaryBlock
            label="Total Operators"
            value={data?.institution.operator}
          />
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <Text fontSize="sm">Users</Text>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full">
          <DashboardSummaryBlock label="Buyers" value={data?.user?.buyer} />
          <DashboardSummaryBlock
            label="Finance Officers"
            value={data?.user.finance_officer}
          />
          <DashboardSummaryBlock
            label="trade officers"
            value={data?.user.trade_officer}
          />
          <DashboardSummaryBlock label="Admins" value={data?.user.admin} />
        </div>
      </div>

      <br />
    </div>
  )
}

export default AdminDashboard
