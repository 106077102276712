import SharedSuspense from "~/components/shared/compoents/suspense"
import AdminPage from "../pages/admin"
import AdminDashboard from "../pages/admin/dashboard"
import ErrorPage from "../pages/error-page"
import routeUrls from "./routes"

import { lazy } from "react"
import { createBrowserRouter, Navigate } from "react-router-dom"

const AreaLevelsAdd = lazy(
  () => import("~/pages/admin/settings/area-levels/create"),
)
const AreaLevelsTable = lazy(
  () => import("~/pages/admin/settings/area-levels/view"),
)

const AdminActors = lazy(() => import("~/pages/admin/actors"))
const AdminActorAmcos = lazy(() => import("~/pages/admin/actors/amcos"))
const AdminActors_AMCOS_Add = lazy(
  () => import("~/pages/admin/actors/amcos-add"),
)
const AdminActorFarmer = lazy(() => import("~/pages/admin/actors/farmers"))
const AdminActors_FARMERS_Add = lazy(
  () => import("~/pages/admin/actors/farmers-add"),
)
const AdminActorUnion = lazy(() => import("~/pages/admin/actors/union"))
const AdminActors_UNION_Add = lazy(
  () => import("~/pages/admin/actors/union-add"),
)
const UnionUpdate = lazy(() => import("~/pages/admin/actors/union-update"))
const AmcosUpdate = lazy(() => import("~/pages/admin/actors/amcos-update"))
const WareHouse = lazy(() => import("~/pages/admin/warehouses/index"))
const WarehouseCreate = lazy(() => import("~/pages/admin/warehouses/create"))
const UpdateWarehouse = lazy(() => import("~/pages/admin/warehouses/update"))
const AdminHelp_Support = lazy(() => import("~/pages/admin/help-support"))
const AdminOrganisations_Buyers = lazy(
  () => import("~/pages/admin/organisation-buyers"),
)
const AdminAreaLevels = lazy(() => import("~/pages/admin/settings/area-levels"))
const AdminOrganisations_Types = lazy(
  () => import("~/pages/admin/settings/organisation-types/index"),
)
const AdminSettingOorganisationTypes_Create = lazy(
  () => import("~/pages/admin/settings/organisation-types/create"),
)
const AdminSettings_Banks_View = lazy(
  () => import("~/pages/admin/settings/banks/index"),
)
const AdminSettingBanks_Create = lazy(
  () => import("~/pages/admin/settings/banks/create"),
)
const AdminSettings_Seasons_View = lazy(
  () => import("~/pages/admin/settings/seasons/index"),
)
const AdminSettingSeasons_Create = lazy(
  () => import("~/pages/admin/settings/seasons/create"),
)
const AdminSettingsCrops = lazy(
  () => import("~/pages/admin/settings/crops/index"),
)
const AdminSetting_Crops_Create = lazy(
  () => import("~/pages/admin/settings/crops/create"),
)
const AdminSettingsCropTypes = lazy(
  () => import("~/pages/admin/settings/crops-types/index"),
)
const AdminSetting_CropTypes_Create = lazy(
  () => import("~/pages/admin/settings/crops-types/create"),
)
const AdminSettingsGrades = lazy(
  () => import("~/pages/admin/settings/grades/index"),
)
const AdminSetting_Grades_Create = lazy(
  () => import("~/pages/admin/settings/grades/create"),
)
const AdminSettingsPackaging = lazy(
  () => import("~/pages/admin/settings/packaging/index"),
)
const AdminSetting_Packaging_Create = lazy(
  () => import("~/pages/admin/settings/packaging/create"),
)
const AdminSettingsMeasurements = lazy(
  () => import("~/pages/admin/settings/units/index"),
)
const AdminSetting_Measurement_Create = lazy(
  () => import("~/pages/admin/settings/units/create"),
)
const AdminSettingsCatalogueTypes = lazy(
  () => import("~/pages/admin/settings/catalogue-types/index"),
)
const AdminSetting_CatalogueTypes_Create = lazy(
  () => import("~/pages/admin/settings/catalogue-types/create"),
)
const AdminSettingsWarehouseTypes = lazy(
  () => import("~/pages/admin/settings/warehouse-types/index"),
)
const AdminSetting_WarehouseTypes_Create = lazy(
  () => import("~/pages/admin/settings/warehouse-types/create"),
)
const AdminSettings = lazy(() => import("~/pages/admin/settings"))

const TradingAreaLevels = lazy(
  () => import("~/pages/trading-officer/settings/area-levels/create"),
)
const TradingOrganisations_Types = lazy(
  () => import("~/pages/trading-officer/settings/organisation-types/index"),
)
const TradingSettingOorganisationTypes_Create = lazy(
  () => import("~/pages/trading-officer/settings/organisation-types/create"),
)
const TradingSettings_Banks_View = lazy(
  () => import("~/pages/trading-officer/settings/banks/index"),
)
const TradingSettingBanks_Create = lazy(
  () => import("~/pages/trading-officer/settings/banks/create"),
)
const TradingSettings_Seasons_View = lazy(
  () => import("~/pages/trading-officer/settings/seasons/index"),
)
const TradingSettingSeasons_Create = lazy(
  () => import("~/pages/trading-officer/settings/seasons/create"),
)
const TradingSettingsCrops = lazy(
  () => import("~/pages/trading-officer/settings/crops/index"),
)
const TradingSetting_Crops_Create = lazy(
  () => import("~/pages/trading-officer/settings/crops/create"),
)
const TradingSettingsCropTypes = lazy(
  () => import("~/pages/trading-officer/settings/crops-types/index"),
)
const TradingSetting_CropTypes_Create = lazy(
  () => import("~/pages/trading-officer/settings/crops-types/create"),
)
const TradingSettingsGrades = lazy(
  () => import("~/pages/trading-officer/settings/grades/index"),
)
const TradingSetting_Grades_Create = lazy(
  () => import("~/pages/trading-officer/settings/grades/create"),
)
const TradingSettingsPackaging = lazy(
  () => import("~/pages/trading-officer/settings/packaging/index"),
)
const TradingSetting_Packaging_Create = lazy(
  () => import("~/pages/trading-officer/settings/packaging/create"),
)
const TradingSettingsMeasurements = lazy(
  () => import("~/pages/trading-officer/settings/units/index"),
)
const TradingSetting_Measurement_Create = lazy(
  () => import("~/pages/trading-officer/settings/units/create"),
)
const TradingSettingsCatalogueTypes = lazy(
  () => import("~/pages/trading-officer/settings/catalogue-types/index"),
)
const TradingSetting_CatalogueTypes_Create = lazy(
  () => import("~/pages/trading-officer/settings/catalogue-types/create"),
)
const TradingSettingsWarehouseTypes = lazy(
  () => import("~/pages/trading-officer/settings/warehouse-types/index"),
)
const TradingSetting_WarehouseTypes_Create = lazy(
  () => import("~/pages/trading-officer/settings/warehouse-types/create"),
)
const TradingSettings = lazy(() => import("~/pages/trading-officer/settings"))

const AdminReports = lazy(() => import("~/pages/admin/reports"))
const AdminStatistics = lazy(() => import("~/pages/admin/statistics"))
const AdminSystemUser = lazy(() => import("~/pages/admin/system-user"))
const CreateSystemUser = lazy(
  () => import("~/pages/admin/system-user/create-system-user"),
)
const SystemUsers_Managers_Add = lazy(
  () => import("~/pages/admin/system-user/managers-add"),
)
const SystemUsers_Officers_Add = lazy(
  () => import("~/pages/admin/system-user/officers-add"),
)
const SystemUsers_IT_Staff_Add = lazy(
  () => import("~/pages/admin/system-user/it-staff-add"),
)
const Root = lazy(() => import("~/pages/root"))
const ForgotPassword = lazy(() => import("~/pages/forgot-password"))
const ForgotPasswordEmailVerification = lazy(
  () => import("~/pages/forgot-password-check-email"),
)
const ResetPassword = lazy(
  () => import("~/pages/forgot-password-reset-password"),
)

const ForgotPasswordSetNew = lazy(
  () => import("~/pages/forgot-password-set-new.tsx"),
)

const TradingOfficerPage = lazy(() => import("~/pages/trading-officer"))
const TradingOfficerDashboard = lazy(
  () => import("~/pages/trading-officer/dashboard"),
)
const Organisation_Buyers_AddBuyer = lazy(
  () => import("~/pages/admin/organisation-buyers/add-buyers"),
)
const CreateCompany = lazy(
  () => import("~/pages/admin/organisation-buyers/create"),
)
const UpdateCompany = lazy(
  () => import("~/pages/admin/organisation-buyers/update"),
)
const Organisation_Buyers_AddOther = lazy(
  () => import("~/pages/admin/organisation-buyers/add-other"),
)

// trading dashboard imports
const TradingActorsRoot = lazy(
  () => import("~/pages/trading-officer/actors/index"),
)

const TradingAmcos = lazy(() => import("~/pages/trading-officer/actors/amcos"))
const TradingUnion = lazy(() => import("~/pages/trading-officer/actors/unions"))

const TradingBuyers = lazy(
  () => import("~/pages/trading-officer/actors/buyers"),
)

const TradingBuyersAdd = lazy(
  () => import("~/pages/trading-officer/actors/buyers-add"),
)

const TradingUnionAdd = lazy(
  () => import("~/pages/trading-officer/actors/union-add"),
)

const TradingUnionUpdate = lazy(
  () => import("~/pages/trading-officer/actors/union-update"),
)

const TradingAmcosAdd = lazy(
  () => import("~/pages/trading-officer/actors/amcos-add"),
)

const TradingDashboard_TradingRoot = lazy(
  () => import("~/pages/trading-officer/trading/index"),
)

const Trading_Lots = lazy(
  () => import("~/pages/trading-officer/trading/lots/index"),
)
const TradingCreateLot = lazy(
  () => import("~/pages/trading-officer/trading/lots/create"),
)
const TradeOfficerReports = lazy(
  () => import("~/pages/trading-officer/reports/index"),
)
const TradingAmcosUpdate = lazy(
  () => import("~/pages/trading-officer/trading/lots/update"),
)
const TradingUploadReceipt = lazy(
  () => import("~/pages/trading-officer/trading/lots/upload-receipt"),
)
const TradingUploadLot = lazy(
  () => import("~/pages/trading-officer/trading/lots/upload-lot"),
)
const UpdateAmcosStorageLot = lazy(
  () => import("~/pages/trading-officer/trading/lots/update"),
)
const Trading_Catalogue = lazy(
  () => import("~/pages/trading-officer/trading/catalogue/index"),
)
const CreateCatalogue = lazy(
  () => import("~/pages/trading-officer/trading/catalogue/create"),
)
const UpdateCatalogue = lazy(
  () => import("~/pages/trading-officer/trading/catalogue/update"),
)
const Trading_Session = lazy(
  () => import("~/pages/trading-officer/trading/session/index"),
)
const Trading_Session_Live = lazy(
  () => import("~/pages/trading-officer/trading/session/bidding"),
)
const TradingSessionCreate = lazy(
  () => import("~/pages/trading-officer/trading/session/create"),
)
const TradeSessionUpdate = lazy(
  () => import("~/pages/trading-officer/trading/session/update"),
)
const TradingResults = lazy(
  () => import("~/pages/trading-officer/trading/results/index"),
)
const TradingOfficer_Results = lazy(
  () => import("~/pages/trading-officer/results"),
)
const TradingOfficer_Result = lazy(
  () => import("~/pages/trading-officer/result"),
)

const VerifyEmail = lazy(() => import("~/pages/verify-email"))

// finance
const FinanceRoot = lazy(() => import("~/pages/finance-officer/index"))
const FinanceHome = lazy(() => import("~/pages/finance-officer/home"))
const FinanceTradeResults = lazy(
  () => import("~/pages/finance-officer/trade-results"),
)
const FinanceTradeResultBySession = lazy(
  () => import("~/pages/finance-officer/trade-result"),
)
const FinanceInvoices = lazy(() => import("~/pages/finance-officer/invoices"))
const FinanceCropBoards = lazy(
  () => import("~/pages/finance-officer/crop-boards"),
)
const FinanceCropBoardDetails = lazy(
  () => import("~/pages/finance-officer/crop-board-details"),
)
const FinanceInvoicesPreview = lazy(
  () => import("~/pages/finance-officer/invoices-preview"),
)
const FinanceFunds = lazy(() => import("~/pages/finance-officer/funds"))
const FinanceReports = lazy(() => import("~/pages/finance-officer/reports"))
const FinanceConfigurations = lazy(
  () => import("~/pages/finance-officer/configurations/index"),
)
const FinanceConfigurationsBeneficiaries = lazy(
  () => import("~/pages/finance-officer/configurations/beneficiaries/list"),
)
const ListSignatories = lazy(
  () => import("~/pages/finance-officer/configurations/signatories/index"),
)
const CreateSignatories = lazy(
  () => import("~/pages/finance-officer/configurations/signatories/create"),
)
const UpdateSignatories = lazy(
  () => import("~/pages/finance-officer/configurations/signatories/update"),
)
const FinanceConfigurationsRatesAndCharges = lazy(
  () => import("~/pages/finance-officer/configurations/rates-and-charges/list"),
)
const FinanceConfigurationsBankAndMMO = lazy(
  () => import("~/pages/finance-officer/configurations/bank-and-mmo/list"),
)
const FinanceCreateBankAndMNO = lazy(
  () => import("~/pages/finance-officer/configurations/bank-and-mmo/create"),
)
const FinanceUpdateBankAndMNO = lazy(
  () => import("~/pages/finance-officer/configurations/bank-and-mmo/update"),
)
const CreateRatesAndCharges = lazy(
  () =>
    import("~/pages/finance-officer/configurations/rates-and-charges/create"),
)
const UpdateRatesAndCharges = lazy(
  () =>
    import("~/pages/finance-officer/configurations/rates-and-charges/update"),
)
const CreateBeneficiaries = lazy(
  () => import("~/pages/finance-officer/configurations/beneficiaries/create"),
)
const UpdateBeneficiaries = lazy(
  () => import("~/pages/finance-officer/configurations/beneficiaries/update"),
)
const FinanceConfigurationsSettlementBanks = lazy(
  () => import("~/pages/finance-officer/configurations/settlement-banks/list"),
)
const FinanceCreateSettlementBanks = lazy(
  () =>
    import("~/pages/finance-officer/configurations/settlement-banks/create"),
)
const FinanceUpdateSettlementBanks = lazy(
  () =>
    import("~/pages/finance-officer/configurations/settlement-banks/update"),
)
const FinanceBuyersList = lazy(
  () => import("~/pages/finance-officer/finance-funds/list"),
)
const FinanceBuyerSecurityDeposit = lazy(
  () => import("~/pages/finance-officer/finance-funds/buyer-security-deposits"),
)
const FinanceBuyerSecurityDepositAdd = lazy(
  () =>
    import("~/pages/finance-officer/finance-funds/buyer-security-deposit-add"),
)

// ceo
const CEOPage = lazy(() => import("~/pages/ceo/index"))

// organisation rep
const OrganisationRepPage = lazy(() => import("~/pages/organisation-rep/index"))

// buyer
const BuyerPage = lazy(() => import("~/pages/buyer/index"))
const BuyerHome = lazy(() => import("~/pages/buyer/home"))
const BuyerTradeResults = lazy(() => import("~/pages/buyer/trade-results"))
const BuyerFinancials = lazy(() => import("~/pages/buyer/financials/index"))
const BuyerTradingCatalogues = lazy(
  () => import("~/pages/buyer/trading/catalogues"),
)
const BuyerTradingLots = lazy(() => import("~/pages/buyer/trading/lots"))
const BuyerTradeResult = lazy(() => import("~/pages/buyer/trade-result"))
const BuyerReports = lazy(() => import("~/pages/buyer/reports"))
const BuyerConfigurations = lazy(() => import("~/pages/buyer/configurations"))
const BuyerFinancialsInvoice = lazy(
  () => import("~/pages/buyer/financials/invoice"),
)

// visitor
const VisitorPage = lazy(() => import("~/pages/visitor/index"))
const VisitorTradeSessions = lazy(
  () => import("~/pages/visitor/trade-sessions"),
)
const VisitorLiveTradeSession = lazy(
  () => import("~/pages/visitor/live-session"),
)

// Union Rep
const UnionRepPage = lazy(() => import("~/pages/union-rep/index"))
const UnionRepHome = lazy(() => import("~/pages/union-rep/home"))
const UnionRepBuyers = lazy(() => import("~/pages/union-rep/buyers"))
const UnionRepTrading = lazy(() => import("~/pages/union-rep/trading"))
const UnionRepReports = lazy(() => import("~/pages/union-rep/reports"))

// Crop Board
const CropBoardPage = lazy(() => import("~/pages/crop-board/index"))
const CropBoardHome = lazy(() => import("~/pages/crop-board/home"))
const CropBoardBuyers = lazy(() => import("~/pages/crop-board/buyers"))
const CropBoardReports = lazy(() => import("~/pages/crop-board/reports"))
const CropBoardResult = lazy(() => import("~/pages/crop-board/result"))
const CropBoardResults = lazy(() => import("~/pages/crop-board/results"))
const CropBoardTrading = lazy(() => import("~/pages/crop-board/trading"))
const CropBoardInvoices = lazy(() => import("~/pages/crop-board/invoices"))
const CropBoardInvoice = lazy(() => import("~/pages/crop-board/invoice"))

const router = createBrowserRouter([
  // login page
  {
    path: routeUrls.authentication.root,
    element: (
      <SharedSuspense>
        <Root />
      </SharedSuspense>
    ),
    errorElement: (
      <SharedSuspense>
        <ErrorPage />
      </SharedSuspense>
    ),
  },

  {
    path: routeUrls.authentication.verifyEmail,
    element: (
      <SharedSuspense>
        <VerifyEmail />
      </SharedSuspense>
    ),
    errorElement: (
      <SharedSuspense>
        <ErrorPage />
      </SharedSuspense>
    ),
  },

  {
    path: routeUrls.authentication.forgotPassword,
    element: (
      <SharedSuspense>
        <ForgotPassword />
      </SharedSuspense>
    ),
    errorElement: (
      <SharedSuspense>
        <ErrorPage />
      </SharedSuspense>
    ),
  },

  {
    path: routeUrls.authentication.checkEmail,
    element: (
      <SharedSuspense>
        <ForgotPasswordEmailVerification />
      </SharedSuspense>
    ),
    errorElement: (
      <SharedSuspense>
        <ErrorPage />
      </SharedSuspense>
    ),
  },

  {
    path: routeUrls.authentication.resetPasswordVerify,
    element: (
      <SharedSuspense>
        <ResetPassword />
      </SharedSuspense>
    ),
    errorElement: (
      <SharedSuspense>
        <ErrorPage />
      </SharedSuspense>
    ),
  },

  {
    path: routeUrls.authentication.resetPasswordConfirm,
    element: (
      <SharedSuspense>
        <ForgotPasswordSetNew />
      </SharedSuspense>
    ),
    errorElement: (
      <SharedSuspense>
        <ErrorPage />
      </SharedSuspense>
    ),
  },

  // admin dashboard
  {
    path: routeUrls.adminDashboard.root,
    element: (
      <SharedSuspense>
        <AdminPage />
      </SharedSuspense>
    ),
    errorElement: (
      <SharedSuspense>
        <ErrorPage />
      </SharedSuspense>
    ),
    children: [
      {
        path: routeUrls.adminDashboard.root,
        element: (
          <SharedSuspense>
            <Navigate replace to={routeUrls.adminDashboard.dashboard} />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.adminDashboard.dashboard,
        element: (
          <SharedSuspense>
            <AdminDashboard />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.adminDashboard.area_levels,
        element: (
          <SharedSuspense>
            <AdminAreaLevels />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.adminDashboard.area_levels_create,
        element: (
          <SharedSuspense>
            <AreaLevelsAdd />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.adminDashboard.warehouse,
        element: (
          <SharedSuspense>
            <WareHouse />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.adminDashboard.warehouse_create,
        element: (
          <SharedSuspense>
            <WarehouseCreate />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.adminDashboard.warehouse_update,
        element: (
          <SharedSuspense>
            <UpdateWarehouse />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.adminDashboard.help_support,
        element: (
          <SharedSuspense>
            <AdminHelp_Support />
          </SharedSuspense>
        ),
      },

      // ADMIN:: organisations and buyers
      {
        path: routeUrls.adminDashboard.organisations_buyers,
        element: (
          <SharedSuspense>
            <AdminOrganisations_Buyers />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.adminDashboard.organisations_buyers_add_buyer,
        element: (
          <SharedSuspense>
            <CreateCompany />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.adminDashboard.organisations_buyers_update_company,
        element: (
          <SharedSuspense>
            <UpdateCompany />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.adminDashboard.organisations_buyers_add_other,
        element: (
          <SharedSuspense>
            <Organisation_Buyers_AddOther />
          </SharedSuspense>
        ),
      },
      // ...................

      {
        path: routeUrls.adminDashboard.reports,
        element: (
          <SharedSuspense>
            <AdminReports />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.adminDashboard.statistics,
        element: (
          <SharedSuspense>
            <AdminStatistics />
          </SharedSuspense>
        ),
      },

      {
        path: routeUrls.adminDashboard.settings,
        element: (
          <SharedSuspense>
            <AdminSettings />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
        children: [
          {
            path: routeUrls.adminDashboard.settings,
            element: (
              <SharedSuspense>
                <Navigate
                  to={routeUrls.adminDashboard.settings_area_levels}
                  replace
                />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.adminDashboard.settings_area_levels,
            element: (
              <SharedSuspense>
                <AdminAreaLevels />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
            children: [
              {
                path: routeUrls.adminDashboard.settings_area_levels,
                element: (
                  <SharedSuspense>
                    <Navigate
                      to={routeUrls.adminDashboard.settings_area_levels_create}
                      replace
                    />
                  </SharedSuspense>
                ),
                errorElement: (
                  <SharedSuspense>
                    <ErrorPage />
                  </SharedSuspense>
                ),
              },
              {
                path: routeUrls.adminDashboard.settings_area_levels_create,
                element: (
                  <SharedSuspense>
                    <AreaLevelsAdd />
                  </SharedSuspense>
                ),
                errorElement: (
                  <SharedSuspense>
                    <ErrorPage />
                  </SharedSuspense>
                ),
              },
              {
                path: routeUrls.adminDashboard.settings_area_levels_view,
                element: (
                  <SharedSuspense>
                    <AreaLevelsTable />
                  </SharedSuspense>
                ),
                errorElement: (
                  <SharedSuspense>
                    <ErrorPage />
                  </SharedSuspense>
                ),
              },
            ],
          },
          {
            path: routeUrls.adminDashboard.settings_organisations_types,
            element: (
              <SharedSuspense>
                <AdminOrganisations_Types />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.adminDashboard.settings_organisations_types_create,
            element: (
              <SharedSuspense>
                <AdminSettingOorganisationTypes_Create />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.adminDashboard.settings_banks,
            element: (
              <SharedSuspense>
                <AdminSettings_Banks_View />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.adminDashboard.settings_banks_create,
            element: (
              <SharedSuspense>
                <AdminSettingBanks_Create />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.adminDashboard.settings_seasons,
            element: (
              <SharedSuspense>
                <AdminSettings_Seasons_View />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.adminDashboard.settings_seasons_create,
            element: (
              <SharedSuspense>
                <AdminSettingSeasons_Create />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.adminDashboard.settings_crops,
            element: (
              <SharedSuspense>
                <AdminSettingsCrops />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.adminDashboard.settings_crops_create,
            element: (
              <SharedSuspense>
                <AdminSetting_Crops_Create />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.adminDashboard.settings_crop_types,
            element: (
              <SharedSuspense>
                <AdminSettingsCropTypes />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.adminDashboard.settings_crop_types_create,
            element: (
              <SharedSuspense>
                <AdminSetting_CropTypes_Create />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.adminDashboard.settings_grades,
            element: (
              <SharedSuspense>
                <AdminSettingsGrades />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.adminDashboard.settings_grades_create,
            element: (
              <SharedSuspense>
                <AdminSetting_Grades_Create />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.adminDashboard.settings_packaging,
            element: (
              <SharedSuspense>
                <AdminSettingsPackaging />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.adminDashboard.settings_packaging_create,
            element: (
              <SharedSuspense>
                <AdminSetting_Packaging_Create />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.adminDashboard.settings_units,
            element: (
              <SharedSuspense>
                <AdminSettingsMeasurements />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.adminDashboard.settings_units_create,
            element: (
              <SharedSuspense>
                <AdminSetting_Measurement_Create />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.adminDashboard.settings_catalogue_type,
            element: (
              <SharedSuspense>
                <AdminSettingsCatalogueTypes />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.adminDashboard.settings_catalogue_type_create,
            element: (
              <SharedSuspense>
                <AdminSetting_CatalogueTypes_Create />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.adminDashboard.settings_warehouse_type,
            element: (
              <SharedSuspense>
                <AdminSettingsWarehouseTypes />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.adminDashboard.settings_warehouse_type_create,
            element: (
              <SharedSuspense>
                <AdminSetting_WarehouseTypes_Create />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
        ],
      },

      // union | amcos | farmers
      {
        path: routeUrls.adminDashboard.union_amcos_farmers_UNION_add,
        element: (
          <SharedSuspense>
            <AdminActors_UNION_Add />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.adminDashboard.union_amcos_farmers_UNION_update,
        element: (
          <SharedSuspense>
            <UnionUpdate />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.adminDashboard.union_amcos_farmers_AMCOS_update,
        element: (
          <SharedSuspense>
            <AmcosUpdate />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },

      {
        path: routeUrls.adminDashboard.union_amcos_farmers_AMCOS_add,
        element: (
          <SharedSuspense>
            <AdminActors_AMCOS_Add />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },

      {
        path: routeUrls.adminDashboard.union_amcos_farmers_FARMERS_add,
        element: (
          <SharedSuspense>
            <AdminActors_FARMERS_Add />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.adminDashboard.union_amcos_farmers,
        element: (
          <SharedSuspense>
            <AdminActors />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
        children: [
          {
            path: routeUrls.adminDashboard.union_amcos_farmers,
            element: (
              <SharedSuspense>
                <AdminActorUnion />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.adminDashboard.union_amcos_farmers_AMCOS,
            element: (
              <SharedSuspense>
                <AdminActorAmcos />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.adminDashboard.union_amcos_farmers_FARMERS,
            element: (
              <SharedSuspense>
                <AdminActorFarmer />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
        ],
      },

      // system users
      {
        path: routeUrls.adminDashboard.system_user,
        element: (
          <SharedSuspense>
            <AdminSystemUser />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.adminDashboard.system_user_add,
        element: (
          <SharedSuspense>
            <CreateSystemUser />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
    ],
  },

  // trading dashboard
  {
    path: routeUrls.tradingDashboard.root,
    element: (
      <SharedSuspense>
        <TradingOfficerPage />
      </SharedSuspense>
    ),
    errorElement: (
      <SharedSuspense>
        <ErrorPage />
      </SharedSuspense>
    ),

    children: [
      {
        path: routeUrls.tradingDashboard.root,
        element: (
          <SharedSuspense>
            <Navigate replace to={routeUrls.tradingDashboard.dashboard} />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.tradingDashboard.dashboard,
        element: (
          <SharedSuspense>
            <TradingOfficerDashboard />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.tradingDashboard.buyers,
        element: (
          <SharedSuspense>
            <TradingBuyers />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.tradingDashboard.union_amcos_buyers,
        element: (
          <SharedSuspense>
            <TradingActorsRoot />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),

        children: [
          {
            path: routeUrls.tradingDashboard.union_amcos_buyers,
            element: (
              <SharedSuspense>
                <Navigate
                  to={routeUrls.tradingDashboard.union_amcos_buyers_amcos}
                  replace
                />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },

          {
            path: routeUrls.tradingDashboard.union_amcos_buyers_amcos,
            element: (
              <SharedSuspense>
                <TradingAmcos />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.tradingDashboard.union_amcos_buyers_union,
            element: (
              <SharedSuspense>
                <TradingUnion />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
        ],
      },
      {
        path: routeUrls.tradingDashboard.union_amcos_buyers_buyers_create,
        element: (
          <SharedSuspense>
            <TradingBuyersAdd />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.tradingDashboard.union_amcos_buyers_union_create,
        element: (
          <SharedSuspense>
            <TradingUnionAdd />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.tradingDashboard.union_amcos_buyers_union_update,
        element: (
          <SharedSuspense>
            <TradingUnionUpdate />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.tradingDashboard.union_amcos_buyers_amcos_create,
        element: (
          <SharedSuspense>
            <TradingAmcosAdd />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },

      {
        path: routeUrls.tradingDashboard.trading_lots_create,
        element: (
          <SharedSuspense>
            <TradingCreateLot />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },

      {
        path: routeUrls.tradingDashboard.trading_reports,
        element: (
          <SharedSuspense>
            <TradeOfficerReports />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },

      {
        path: routeUrls.tradingDashboard.trading_lots_amcos_storage_update,
        element: (
          <SharedSuspense>
            <TradingAmcosUpdate />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },

      {
        path: routeUrls.tradingDashboard.trading_lots_upload_receipt,
        element: (
          <SharedSuspense>
            <TradingUploadReceipt />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.tradingDashboard.trading_lots_upload_lot,
        element: (
          <SharedSuspense>
            <TradingUploadLot />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },

      {
        path: routeUrls.tradingDashboard.trading_lots_amcos_storage_update,
        element: (
          <SharedSuspense>
            <UpdateAmcosStorageLot />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },

      {
        path: routeUrls.tradingDashboard.trading_catalogue_create,
        element: (
          <SharedSuspense>
            <CreateCatalogue />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.tradingDashboard.trading_session_create,
        element: (
          <SharedSuspense>
            <TradingSessionCreate />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.tradingDashboard.trading_session_live,
        element: (
          <SharedSuspense>
            <Trading_Session_Live />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.tradingDashboard.trading_session_update,
        element: (
          <SharedSuspense>
            <TradeSessionUpdate />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },

      {
        path: routeUrls.tradingDashboard.trading_catalogue_update,
        element: (
          <SharedSuspense>
            <UpdateCatalogue />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },

      {
        path: routeUrls.tradingDashboard.trading,
        element: (
          <SharedSuspense>
            <TradingDashboard_TradingRoot />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),

        children: [
          {
            path: routeUrls.tradingDashboard.trading,
            element: (
              <SharedSuspense>
                <Navigate
                  to={routeUrls.tradingDashboard.trading_lots}
                  replace
                />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.tradingDashboard.trading_lots,
            element: (
              <SharedSuspense>
                <Trading_Lots />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },

          {
            path: routeUrls.tradingDashboard.trading_catalogue,
            element: (
              <SharedSuspense>
                <Trading_Catalogue />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.tradingDashboard.trading_session,
            element: (
              <SharedSuspense>
                <Trading_Session />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.tradingDashboard.trading_results,
            element: (
              <SharedSuspense>
                <TradingResults />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
        ],
      },
      {
        path: routeUrls.tradingDashboard.trading_results_2,
        element: (
          <SharedSuspense>
            <TradingOfficer_Results />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.tradingDashboard.trading_result,
        element: (
          <SharedSuspense>
            <TradingOfficer_Result />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },

      // trading settings
      {
        path: routeUrls.tradingDashboard.settings,
        element: (
          <SharedSuspense>
            <TradingSettings />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
        children: [
          {
            path: routeUrls.tradingDashboard.settings,
            element: (
              <SharedSuspense>
                <Navigate
                  to={routeUrls.tradingDashboard.settings_area_levels}
                  replace
                />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.tradingDashboard.settings_area_levels,
            element: (
              <SharedSuspense>
                <TradingAreaLevels />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
            children: [
              {
                path: routeUrls.tradingDashboard.settings_area_levels,
                element: (
                  <SharedSuspense>
                    <Navigate
                      to={
                        routeUrls.tradingDashboard.settings_area_levels_create
                      }
                      replace
                    />
                  </SharedSuspense>
                ),
                errorElement: (
                  <SharedSuspense>
                    <ErrorPage />
                  </SharedSuspense>
                ),
              },
              {
                path: routeUrls.tradingDashboard.settings_area_levels_create,
                element: (
                  <SharedSuspense>
                    <AreaLevelsAdd />
                  </SharedSuspense>
                ),
                errorElement: (
                  <SharedSuspense>
                    <ErrorPage />
                  </SharedSuspense>
                ),
              },
              {
                path: routeUrls.tradingDashboard.settings_area_levels_view,
                element: (
                  <SharedSuspense>
                    <AreaLevelsTable />
                  </SharedSuspense>
                ),
                errorElement: (
                  <SharedSuspense>
                    <ErrorPage />
                  </SharedSuspense>
                ),
              },
            ],
          },
          {
            path: routeUrls.tradingDashboard.settings_organisations_types,
            element: (
              <SharedSuspense>
                <TradingOrganisations_Types />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.tradingDashboard
              .settings_organisations_types_create,
            element: (
              <SharedSuspense>
                <TradingSettingOorganisationTypes_Create />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.tradingDashboard.settings_banks,
            element: (
              <SharedSuspense>
                <TradingSettings_Banks_View />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.tradingDashboard.settings_banks_create,
            element: (
              <SharedSuspense>
                <TradingSettingBanks_Create />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.tradingDashboard.settings_seasons,
            element: (
              <SharedSuspense>
                <TradingSettings_Seasons_View />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.tradingDashboard.settings_seasons_create,
            element: (
              <SharedSuspense>
                <TradingSettingSeasons_Create />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.tradingDashboard.settings_crops,
            element: (
              <SharedSuspense>
                <TradingSettingsCrops />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.tradingDashboard.settings_crops_create,
            element: (
              <SharedSuspense>
                <TradingSetting_Crops_Create />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.tradingDashboard.settings_crop_types,
            element: (
              <SharedSuspense>
                <TradingSettingsCropTypes />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.tradingDashboard.settings_crop_types_create,
            element: (
              <SharedSuspense>
                <TradingSetting_CropTypes_Create />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.tradingDashboard.settings_grades,
            element: (
              <SharedSuspense>
                <TradingSettingsGrades />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.tradingDashboard.settings_grades_create,
            element: (
              <SharedSuspense>
                <TradingSetting_Grades_Create />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.tradingDashboard.settings_packaging,
            element: (
              <SharedSuspense>
                <TradingSettingsPackaging />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.tradingDashboard.settings_packaging_create,
            element: (
              <SharedSuspense>
                <TradingSetting_Packaging_Create />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.tradingDashboard.settings_units,
            element: (
              <SharedSuspense>
                <TradingSettingsMeasurements />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.tradingDashboard.settings_units_create,
            element: (
              <SharedSuspense>
                <TradingSetting_Measurement_Create />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.tradingDashboard.settings_catalogue_type,
            element: (
              <SharedSuspense>
                <TradingSettingsCatalogueTypes />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.tradingDashboard.settings_catalogue_type_create,
            element: (
              <SharedSuspense>
                <TradingSetting_CatalogueTypes_Create />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.tradingDashboard.settings_warehouse_type,
            element: (
              <SharedSuspense>
                <TradingSettingsWarehouseTypes />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.tradingDashboard.settings_warehouse_type_create,
            element: (
              <SharedSuspense>
                <TradingSetting_WarehouseTypes_Create />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
        ],
      },
    ],
  },

  // finance dashboard
  {
    path: routeUrls.financeDashboard.root,
    element: (
      <SharedSuspense>
        <FinanceRoot />
      </SharedSuspense>
    ),
    errorElement: (
      <SharedSuspense>
        <ErrorPage />
      </SharedSuspense>
    ),
    children: [
      {
        path: routeUrls.financeDashboard.root,
        element: (
          <SharedSuspense>
            <Navigate replace to={routeUrls.financeDashboard.home} />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.financeDashboard.home,
        element: (
          <SharedSuspense>
            <FinanceHome />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.financeDashboard.finance_funds,
        element: (
          <SharedSuspense>
            <FinanceBuyersList />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.financeDashboard.finance_funds_security_deposits,
        element: (
          <SharedSuspense>
            <FinanceBuyerSecurityDeposit />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
        children: [
          {
            path: routeUrls.financeDashboard
              .finance_funds_security_deposits_add,
            element: (
              <SharedSuspense>
                <FinanceBuyerSecurityDepositAdd />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
        ],
      },
      {
        path: routeUrls.financeDashboard.trade_results,
        element: (
          <SharedSuspense>
            <FinanceTradeResults />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.financeDashboard.trade_results_by_session,
        element: (
          <SharedSuspense>
            <FinanceTradeResultBySession />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.financeDashboard.crop_board,
        element: (
          <SharedSuspense>
            <FinanceCropBoards />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.financeDashboard.crop_board_details,
        element: (
          <SharedSuspense>
            <FinanceCropBoardDetails />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.financeDashboard.invoices,
        element: (
          <SharedSuspense>
            <FinanceInvoices />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.financeDashboard.invoices_preview,
        element: (
          <SharedSuspense>
            <FinanceInvoicesPreview />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.financeDashboard.funds,
        element: (
          <SharedSuspense>
            <FinanceFunds />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.financeDashboard.reports,
        element: (
          <SharedSuspense>
            <FinanceReports />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.financeDashboard.configurations,
        element: (
          <SharedSuspense>
            <FinanceConfigurations />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
        children: [
          {
            path: routeUrls.financeDashboard.configurations,
            element: (
              <SharedSuspense>
                <Navigate
                  to={routeUrls.financeDashboard.configurations_beneficiaries}
                  replace
                />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.financeDashboard.configurations_beneficiaries,
            element: (
              <SharedSuspense>
                <FinanceConfigurationsBeneficiaries />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.financeDashboard.configurations_signatory,
            element: (
              <SharedSuspense>
                <ListSignatories />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.financeDashboard.configurations_signatory_create,
            element: (
              <SharedSuspense>
                <CreateSignatories />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.financeDashboard.configurations_signatory_update,
            element: (
              <SharedSuspense>
                <UpdateSignatories />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.financeDashboard.configurations_rates_charges,
            element: (
              <SharedSuspense>
                <FinanceConfigurationsRatesAndCharges />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.financeDashboard.configurations_bank_mmo,
            element: (
              <SharedSuspense>
                <FinanceConfigurationsBankAndMMO />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.financeDashboard.configurations_bank_mmo_create,
            element: (
              <SharedSuspense>
                <FinanceCreateBankAndMNO />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.financeDashboard.configurations_bank_mmo_update,
            element: (
              <SharedSuspense>
                <FinanceUpdateBankAndMNO />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.financeDashboard
              .configurations_rates_charges_create,
            element: (
              <SharedSuspense>
                <CreateRatesAndCharges />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.financeDashboard
              .configurations_rates_charges_update,
            element: (
              <SharedSuspense>
                <UpdateRatesAndCharges />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.financeDashboard
              .configurations_beneficiaries_create,
            element: (
              <SharedSuspense>
                <CreateBeneficiaries />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.financeDashboard
              .configurations_beneficiaries_update,
            element: (
              <SharedSuspense>
                <UpdateBeneficiaries />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.financeDashboard.configurations_settlement_banks,
            element: (
              <SharedSuspense>
                <FinanceConfigurationsSettlementBanks />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.financeDashboard
              .configurations_settlement_banks_create,
            element: (
              <SharedSuspense>
                <FinanceCreateSettlementBanks />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
          {
            path: routeUrls.financeDashboard
              .configurations_settlement_banks_update,
            element: (
              <SharedSuspense>
                <FinanceUpdateSettlementBanks />
              </SharedSuspense>
            ),
            errorElement: (
              <SharedSuspense>
                <ErrorPage />
              </SharedSuspense>
            ),
          },
        ],
      },
    ],
  },

  // ceo dashboard
  {
    path: routeUrls.ceoDashboard.root,
    element: (
      <SharedSuspense>
        <CEOPage />
      </SharedSuspense>
    ),
    errorElement: (
      <SharedSuspense>
        <ErrorPage />
      </SharedSuspense>
    ),
    children: [],
  },

  // organisation-rep dashboard
  {
    path: routeUrls.organisationRepDashboard.root,
    element: (
      <SharedSuspense>
        <OrganisationRepPage />
      </SharedSuspense>
    ),
    errorElement: (
      <SharedSuspense>
        <ErrorPage />
      </SharedSuspense>
    ),
    children: [],
  },

  // buyer dashboard
  {
    path: routeUrls.buyerDashboard.root,
    element: (
      <SharedSuspense>
        <BuyerPage />
      </SharedSuspense>
    ),
    errorElement: (
      <SharedSuspense>
        <ErrorPage />
      </SharedSuspense>
    ),
    children: [
      {
        path: routeUrls.buyerDashboard.root,
        element: <Navigate replace to={routeUrls.buyerDashboard.trading} />,
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.buyerDashboard.home,
        element: (
          <SharedSuspense>
            <BuyerHome />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.buyerDashboard.reports,
        element: (
          <SharedSuspense>
            <BuyerReports />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.buyerDashboard.configurations,
        element: (
          <SharedSuspense>
            <BuyerConfigurations />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.buyerDashboard.invoice,
        element: (
          <SharedSuspense>
            <BuyerFinancialsInvoice />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.buyerDashboard.tradeResults,
        element: (
          <SharedSuspense>
            <BuyerTradeResults />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.buyerDashboard.trade_financials,
        element: (
          <SharedSuspense>
            <BuyerFinancials />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.buyerDashboard.trading,
        element: (
          <SharedSuspense>
            <BuyerTradingCatalogues />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.buyerDashboard.trading_lots,
        element: (
          <SharedSuspense>
            <BuyerTradingLots />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.buyerDashboard.trading_results_session,
        element: (
          <SharedSuspense>
            <BuyerTradeResult />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
    ],
  },

  // visitor dashboard
  {
    path: routeUrls.visitorDashboard.root,
    element: (
      <SharedSuspense>
        <VisitorPage />
      </SharedSuspense>
    ),
    errorElement: (
      <SharedSuspense>
        <ErrorPage />
      </SharedSuspense>
    ),
    children: [
      {
        path: routeUrls.visitorDashboard.root,
        element: (
          <Navigate replace to={routeUrls.visitorDashboard.trade_sessions} />
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.visitorDashboard.trade_sessions,
        element: (
          <SharedSuspense>
            <VisitorTradeSessions />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.visitorDashboard.live_session,
        element: (
          <SharedSuspense>
            <VisitorLiveTradeSession />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
    ],
  },

  // union rep
  {
    path: routeUrls.unionRepDashboard.root,
    element: (
      <SharedSuspense>
        <UnionRepPage />
      </SharedSuspense>
    ),
    errorElement: (
      <SharedSuspense>
        <ErrorPage />
      </SharedSuspense>
    ),

    children: [
      {
        path: routeUrls.unionRepDashboard.root,
        element: (
          <SharedSuspense>
            <Navigate replace to={routeUrls.unionRepDashboard.dashboard} />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.unionRepDashboard.dashboard,
        element: (
          <SharedSuspense>
            <UnionRepHome />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.unionRepDashboard.sellers,
        element: (
          <SharedSuspense>
            <UnionRepBuyers />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.unionRepDashboard.trading_reports,
        element: (
          <SharedSuspense>
            <UnionRepReports />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.unionRepDashboard.trading,
        element: (
          <SharedSuspense>
            <UnionRepTrading />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
    ],
  },

  // crop board
  {
    path: routeUrls.cropBoardDashboard.root,
    element: (
      <SharedSuspense>
        <CropBoardPage />
      </SharedSuspense>
    ),
    errorElement: (
      <SharedSuspense>
        <ErrorPage />
      </SharedSuspense>
    ),

    children: [
      {
        path: routeUrls.cropBoardDashboard.root,
        element: (
          <SharedSuspense>
            <Navigate replace to={routeUrls.cropBoardDashboard.dashboard} />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.cropBoardDashboard.dashboard,
        element: (
          <SharedSuspense>
            <CropBoardHome />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.cropBoardDashboard.buyers,
        element: (
          <SharedSuspense>
            <CropBoardBuyers />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.cropBoardDashboard.trading_reports,
        element: (
          <SharedSuspense>
            <CropBoardReports />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.cropBoardDashboard.trading_result,
        element: (
          <SharedSuspense>
            <CropBoardResult />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.cropBoardDashboard.trading_results,
        element: (
          <SharedSuspense>
            <CropBoardResults />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.cropBoardDashboard.trading,
        element: (
          <SharedSuspense>
            <CropBoardTrading />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.cropBoardDashboard.invoices,
        element: (
          <SharedSuspense>
            <CropBoardInvoices />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
      {
        path: routeUrls.cropBoardDashboard.invoice,
        element: (
          <SharedSuspense>
            <CropBoardInvoice />
          </SharedSuspense>
        ),
        errorElement: (
          <SharedSuspense>
            <ErrorPage />
          </SharedSuspense>
        ),
      },
    ],
  },
])

export default router
