import { Spin } from "antd"
import { Suspense } from "react"

const SharedSuspense: React.FunctionComponent<{
  children: React.ReactNode
}> = ({ children }) => (
  <Suspense
    fallback={
      <div className="w-full h-screen flex items-center justify-center grow">
        <Spin />
      </div>
    }
  >
    {children}
  </Suspense>
)

export default SharedSuspense
