import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import Cookies from "js-cookie"

export const baseUrl = (import.meta.env.VITE_API_BASE_URL as string) ?? ""

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: { "Content-Type": "application/json" },
})

function handleAddConfigToRequest(config: AxiosRequestConfig<any>) {
  let token = Cookies.get("access") ?? ""
  if (token)
    config.headers && (config.headers["Authorization"] = "Bearer " + token)
  return config
}

function handleRequestError(error: any) {
  return Promise.reject(error)
}

function handleNormalResponse(response: AxiosResponse) {
  return response
}

async function handleErrorResponse(err: any) {
  const originalConfig = err.config

  if (originalConfig.url !== "/auth/signin" && err.response) {
    // Access Token was expired
    if (err.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true

      try {
        let refresh = Cookies.get("refresh") ?? ""
        const rs = await axiosInstance.post("/auth/token/refresh/", {
          refresh,
        })

        // updating the new token
        const { access } = rs.data
        Cookies.set("access", access)

        return axiosInstance(originalConfig)
      } catch (_error) {
        return (window.location.href = `/`)
      }
    }
  }

  return Promise.reject(err)
}

async function handleErrorNormalResponse(err: any) {
  return Promise.reject(err)
}

export default function apiCaller({ isPublic }: { isPublic?: boolean }) {
  if (isPublic) {
    axiosInstance.interceptors.request.use(
      (config) => config,
      handleRequestError,
    )
    axiosInstance.interceptors.response.use(
      handleNormalResponse,
      handleErrorNormalResponse,
    )
  } else {
    axiosInstance.interceptors.request.use(
      // @ts-ignore
      handleAddConfigToRequest,
      handleRequestError,
    )
    axiosInstance.interceptors.response.use(
      handleNormalResponse,
      handleErrorResponse,
    )
  }

  return axiosInstance
}
