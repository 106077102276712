import { useGetThemeColors } from "~/stores/theme"
import Logo from "./logo"
import SharedText from "./text"

const SharedFooter: React.FunctionComponent = () => {
  const colors = useGetThemeColors()
  return (
    <footer
      style={{
        borderColor: colors.border,
        backgroundColor: colors.backgroundSidebar,
      }}
      className="w-full border-t py-2 flex items-center justify-center gap-1"
    >
      <Logo className="h-10" />
      <SharedText.extraSmall>&#169; 2023</SharedText.extraSmall>
    </footer>
  )
}

export default SharedFooter
