import { Heading, HeadingProps, Text } from "@chakra-ui/react"
import { Typography } from "antd"
import { TextProps } from "antd/es/typography/Text"
import useThemeStore from "~/stores/theme"

const HeadingText: React.FunctionComponent<
  { children: React.ReactNode } & HeadingProps
> = ({ children, ...props }) => {
  const { themeMode, colors, changeTheme } = useThemeStore()
  const textColor = colors(themeMode).textMain

  return (
    <Heading color={textColor} {...props}>
      {children}
    </Heading>
  )
}

const ChakraTitle: React.FunctionComponent<{
  title: React.ReactNode
  size?: "xs" | "sm" | "lg"
}> = ({ title, size }) => {
  const { themeMode, colors, changeTheme } = useThemeStore()
  const textColor = colors(themeMode).textMain
  return (
    <Text
      color={textColor}
      fontSize={size === "lg" ? "4xl" : size === "sm" ? "2xl" : "xl"}
      fontWeight="bold"
    >
      {title}
    </Text>
  )
}

interface ITitleText {
  children: React.ReactNode
}

const TitleText: React.FunctionComponent<
  ITitleText & TextProps & React.RefAttributes<HTMLSpanElement>
> = ({ children, ...props }) => {
  const { themeMode, colors, changeTheme } = useThemeStore()
  const textColor = colors(themeMode).textMain
  return (
    <Typography.Title
      {...props}
      className={`${props.className}`}
      style={{ color: textColor, ...props.style }}
    >
      {children}
    </Typography.Title>
  )
}

// --------------

interface ILargeText {
  children: React.ReactNode
}

const LargeText: React.FunctionComponent<
  ILargeText & TextProps & React.RefAttributes<HTMLSpanElement>
> = ({ children, ...props }) => {
  const { themeMode, colors, changeTheme } = useThemeStore()
  const textColor = colors(themeMode).textMain
  return (
    <Typography.Text
      {...props}
      className={`text-lg font-semibold ${props.className}`}
      style={{ color: textColor, ...props.style }}
    >
      {children}
    </Typography.Text>
  )
}

// --------------

interface INormalText {
  children: React.ReactNode
}

const NormalText: React.FunctionComponent<
  INormalText & TextProps & React.RefAttributes<HTMLSpanElement>
> = ({ children, ...props }) => {
  const { themeMode, colors, changeTheme } = useThemeStore()
  const textColor = colors(themeMode).textMain
  return (
    <Typography.Text
      {...props}
      className={`text-base  ${props.className}`}
      style={{ color: textColor, ...props.style }}
    >
      {children}
    </Typography.Text>
  )
}

// --------------

interface ISmallText {
  children: React.ReactNode
}

const SmallText: React.FunctionComponent<
  ISmallText & TextProps & React.RefAttributes<HTMLSpanElement>
> = ({ children, ...props }) => {
  const { themeMode, colors, changeTheme } = useThemeStore()
  const textColor = colors(themeMode).textMain
  return (
    <Typography.Text
      {...props}
      className={`text-sm  ${props.className}`}
      style={{ color: textColor, ...props.style }}
    >
      {children}
    </Typography.Text>
  )
}

// --------------

interface IExtraSmallText {
  children: React.ReactNode
}

const ExtraSmallText: React.FunctionComponent<
  IExtraSmallText & TextProps & React.RefAttributes<HTMLSpanElement>
> = ({ children, ...props }) => {
  const { themeMode, colors, changeTheme } = useThemeStore()
  const textColor = colors(themeMode).textSub
  return (
    <Typography.Text
      {...props}
      className={`text-xs text-gray-700 ${props.className}`}
      style={{ color: textColor, ...props.style }}
    >
      {children}
    </Typography.Text>
  )
}

const SharedText = {
  heading: HeadingText,
  title: TitleText,
  large: LargeText,
  normal: NormalText,
  small: SmallText,
  extraSmall: ExtraSmallText,
  chakraTitle: ChakraTitle,
}

export default SharedText
