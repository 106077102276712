import { Outlet } from "react-router-dom"
import useIsAdmin from "~/hooks/authentication/use-is-admin"
import AdminSidebar from "~/components/admin/sidebar"
import DashboardLayout from "~/components/shared/layouts/dashboard"

const AdminPage = () => {
  useIsAdmin()
  return <DashboardLayout Sidebar={<AdminSidebar />} Contents={<Outlet />} />
}

export default AdminPage
