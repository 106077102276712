import { ChevronDownIcon } from "@chakra-ui/icons"
import {
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Menu,
  Box,
  Text,
  Spinner,
} from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { BiUser } from "react-icons/bi"
import { BsDot } from "react-icons/bs"
import { FiSettings, FiLogOut, FiChevronDown } from "react-icons/fi"
import { useNavigate } from "react-router-dom"
import SharedText from "~/components/shared/compoents/text"
import routeUrls from "~/routing/routes"
import { IcompanyFormData } from "~/stores/company/create"
import { useGetThemeColors } from "~/stores/theme"
import { getUserData, useUserInfoStore } from "~/stores/user-data"
import { clearTokens } from "~/_helpers/auth-token-utils"
import getCompanyService from "~/_services/company/get"

const DesktopProfile: React.FunctionComponent = () => {
  const { clearData } = useUserInfoStore()

  const navigate = useNavigate()

  const colors = useGetThemeColors()
  const { name, getRole } = getUserData()
  const { company } = useUserInfoStore()

  const { fnameInitial, lnameInitial } = (function () {
    let fnameInitial = ""
    let lnameInitial = ""

    if (name) {
      let splitName = name.split(" ")
      fnameInitial = splitName[0][0] ?? ""
      lnameInitial = splitName[1][0] ?? ""
    }
    return { fnameInitial, lnameInitial }
  })()

  const menuList = [
    // { label: "Profile", onClick: () => {}, Icon: <BiUser /> },
    // { label: "Settings", onClick: () => {}, Icon: <FiSettings /> },
    { divider: true },
    {
      label: "Log out",
      onClick: () => {
        clearData()
        clearTokens()
        navigate(routeUrls.authentication.root, { replace: true })
      },
      Icon: <FiLogOut />,
    },
  ]

  const [companyLoading, setCompanyLoading] = useState(false)
  const [companyData, setCompanyData] = useState<IcompanyFormData | undefined>(
    undefined,
  )
  useEffect(() => {
    if (company) {
      ;(async function () {
        try {
          setCompanyLoading(true)
          const { data } = await getCompanyService({ id: company })
          setCompanyData(data)
          setCompanyLoading(false)
        } catch (error) {
          setCompanyLoading(false)
          throw error
        }
      })()
    }
  }, [company])

  return (
    <Menu>
      <MenuButton
        as={Box}
        rightIcon={<ChevronDownIcon />}
        _active={{
          backgroundColor: colors.backgroundContent,
          borderColor: colors.border,
        }}
        _hover={{
          backgroundColor: colors.backgroundContent,
          borderColor: colors.border,
        }}
        className="rounded-xl border border-transparent transition-all cursor-pointer"
      >
        <Box className="flex items-center gap-2 w-fit py-2 px-4">
          <div className="h-8 w-8 rounded-full bg-green-400 flex items-center justify-center">
            <SharedText.extraSmall
              className="font-semibold uppercase"
              style={{ color: "#212121" }}
            >
              {fnameInitial} {lnameInitial}
            </SharedText.extraSmall>
          </div>
          <div className="flex flex-col gap-0">
            <SharedText.extraSmall className="font-semibold capitalize">
              {name}
            </SharedText.extraSmall>

            <div className="flex items-center gap-1">
              <SharedText.extraSmall
                style={{ color: "GrayText" }}
                className="font-semibold text-[11px]"
              >
                {getRole()}
              </SharedText.extraSmall>

              {companyLoading ? (
                <Spinner size="xs" />
              ) : (
                <SharedText.extraSmall
                  hidden={!Boolean(company && companyData)}
                  className="font-semibold text-blue-500 text-[11px] flex items-center gap-1"
                >
                  <BsDot /> {companyData?.abbreviation}
                </SharedText.extraSmall>
              )}
            </div>
          </div>
          <FiChevronDown style={{ color: colors.textSub }} className="ml-2" />
        </Box>
      </MenuButton>
      <MenuList
        bg={colors.backgroundContent}
        borderColor={colors.border}
        p={2}
        className="flex flex-col gap-1 border"
      >
        {menuList.map(({ divider, label, onClick, Icon }, index) => {
          if (label)
            return (
              <MenuItem
                key={index}
                onClick={onClick}
                _hover={{
                  bg: colors.backgroundSidebar,
                  fontWeight: "semibold",
                }}
                bg={colors.backgroundContent}
                color={colors.textSub}
                className="rounded-lg text-sm flex items-center gap-2 transition-all"
              >
                {Icon}
                <Text>{label}</Text>
              </MenuItem>
            )
          if (divider) return <MenuDivider key={index} />
          return <></>
        })}
      </MenuList>
    </Menu>
  )
}

export default DesktopProfile
