import apiCaller from "~/_helpers/api-caller"

export interface IAdminSummary {
  crop: number
  crop_types: number
  grades: number
  union: number
  amcos: number
  institution: { buyer: number; organization: number; operator: number }
  user: {
    admin: number
    trade_officer: number
    finance_officer: number
    buyer: number
  }
}

export default function adminSummaryService() {
  let url = `admini/summary/`
  return apiCaller({ isPublic: false }).get<IAdminSummary>(url)
}
