import { create } from "zustand"
import { persist } from "zustand/middleware"

export type T_ThemeMode = "light" | "dark"

export interface IThemeStore {
  themeMode: T_ThemeMode
  colors: (themeMode: T_ThemeMode) => ReturnType<typeof GET_COLOR_SCHEME>
  changeTheme: (themeMode: T_ThemeMode) => void
}

const useThemeStore = create<IThemeStore>()(
  persist(
    (set, get) => ({
      themeMode: "light",
      colors: (themeMode) => GET_COLOR_SCHEME({ themeMode }),
      changeTheme: (themeMode) => set(() => ({ themeMode })),
    }),
    {
      name: "theme-storage",
    },
  ),
)

export default useThemeStore

const GET_COLOR_SCHEME = ({ themeMode }: { themeMode: T_ThemeMode }) => {
  let isLight = themeMode === "light"
  return {
    backgroundContent: isLight ? "#ffffff" : "#191919",
    backgroundSidebar: isLight ? "#F4F4F6" : "#212121",
    border: isLight ? "#EDE4E0" : "#413543",
    textMain: isLight ? "#191919" : "white",
    textSub: isLight ? "#413543" : "whitesmoke",
    brandPrimary: "#2FA836",
    brandSecondary: "#F9CA0F",
    email: "#3C79F5",
    inactive: "#DF2E38",
    active: "#F7DB6A",
  }
}

export const useGetThemeData = () => {
  return useThemeStore()
}

export const useGetThemeColors = () => {
  const { themeMode, colors, changeTheme } = useThemeStore()
  return colors(themeMode)
}
