const AUTHENTICATION_MODULE_ROUTES = {
  root: "/",
  forgotPassword: "/forgot/password",
  checkEmail: "/forgot/password/check-email/:email",
  _checkEmail: (email: string) => `/forgot/password/check-email/${email}`,
  resetPasswordVerify: "/reset-password/:uidb4/:token",
  resetPasswordConfirm: "/confirm-reset-password/:uidb64/:token",
  _resetPasswordConfirm: ({
    uidb64,
    token,
  }: {
    uidb64: string
    token: string
  }) => `/confirm-reset-password/${uidb64}/${token}`,
  verifyEmail: "/verify-email/:token",
}

export default AUTHENTICATION_MODULE_ROUTES
