import { Text } from "@chakra-ui/react"
import { lazy } from "react"
import { BsArrowClockwise, BsArrowLeft } from "react-icons/bs"
import { useNavigate, useRouteError } from "react-router-dom"

function ErrorPage() {
  const error = useRouteError() as any
  const navigate = useNavigate()
  const goBack = () => navigate(-1)

  return (
    <div className="w-full max-w-[500px] mx-auto flex flex-col items-center justify-center gap-2 border p-4 rounded-md">
      <h1 className="text-4xl font-bold">Sorry!</h1>
      <Text className="text-gray-400 text-xl">
        Something seems to be not working
      </Text>

      <div className="flex items-center gap-4">
        <div
          onClick={goBack}
          className="border px-4 py-2 rounded-full group cursor-pointer hover:bg-blue-50 text-sm flex items-center gap-2 transition-all textbl "
        >
          <BsArrowLeft className="group-hover:mr-2 transition-all" />
          <p>Go back</p>
        </div>
        <div
          onClick={() => window.location.reload()}
          className="border px-4 py-2 rounded-full group cursor-pointer bg-red-50 hover:bg-red-100 text-sm flex items-center gap-2 transition-all textbl "
        >
          <BsArrowClockwise className="group-hover:mr-2 transition-all" />
          <p>Reload</p>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
